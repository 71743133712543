import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import Login from './Login';
import Footer from './Footer';
import Loading from './Loading';
import AdminPanel from './AdminPanel/AdminPanel';

function App() {

  const [loading, setLoading] = useState<boolean>(false)

  const [email, setEmail] = useState<string>('')
  
  const [token, setToken] = useState<string | undefined>(undefined)

  return (
    <div className="App">
      <Header />
      <AdminPanel setLoading={setLoading} token={{ get: token, set: setToken }}/>
      <Footer />
      {loading ? (
        <Loading />
      ) : token === undefined ? (
        <Login setToken={setToken} setLoading={setLoading} email={{ get: email, set: setEmail }} />
      ) : <></>}
    </div>
  );
}

export default App;
