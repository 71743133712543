/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './Modals.css';
import { castRubroPago } from '../Helpers/casterBack';
import { faPencil, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminConfigurarRubro, BackendAdminEditarFechaPago, productoAdmin } from '../PeopleLoansSDKAdmin';
import { castMoneyToShow, castTimestampToShow } from '../Helpers/textOutput';
import { rubroPago, getCantidadPagadaCuota, getCantidadCuota } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import { pago, getCuotas } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import Modal from './Modal';
import EditarCuota from './EditarCuota';
import { t } from '../traduccion/t';

interface VerCuotasProps {
  credito: productoAdmin | undefined
  reloadInfo(): void
  token: string | undefined
}

function InfoCuota(props: { titulo: string, info?: string, detalle?: string, colorInfo?: string }) {
  return (
    <div className='DefaultTwoHVertical' style={{ backgroundColor: 'transparent' }}>
      <h1 style={{ margin: 10, backgroundColor: 'transparent' }}>{props.titulo}</h1>
      {props.info && <h2 style={{ margin: 10, color: props.colorInfo ?? '#000000', backgroundColor: 'transparent' }}>{props.info}</h2>}
      {props.detalle && <h2 style={{ margin: 10, color: '#808080', backgroundColor: 'transparent' }}>{props.detalle}</h2>}
    </div>
  )
}

interface ListCuotaProps {
  idx: number
  pago: pago
  tipo: 'actual' | 'pasado' | 'futuro' | 'mora'
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setPago: React.Dispatch<React.SetStateAction<{
    pay: pago;
    cuota: number;
  } | undefined>>
  loading: boolean
}

function ListCuota(props: ListCuotaProps) {
  return (
    <div className='DefaultColsModal' style={{ backgroundColor: props.tipo === 'pasado' ? '#C0C0C0' : props.tipo === 'actual' ? '#FFFF99' : '#FFFFFF' }}>
      <InfoCuota titulo={'Cuota'} info={props.idx.toString()} />
      {props.tipo === 'mora' && <FontAwesomeIcon icon={faWarning} color='#FF0000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      <InfoCuota titulo={'Fecha límite de pago'} info={castTimestampToShow(props.pago.fechaLimitePago)} colorInfo={props.tipo === 'mora' ? '#FF0000' : '#000000'} />
      <InfoCuota titulo={'Cantidad Pagada'} info={castMoneyToShow(getCantidadPagadaCuota(props.pago), 'COP') + ' (con mora)'} detalle={castMoneyToShow(getCantidadCuota(props.pago), 'COP') + ' (sin mora)'} />
      <InfoCuota key={'Capital'} titulo={castRubroPago('Capital')} info={castMoneyToShow(props.pago.Capital.cantidadPagada, 'COP')} detalle={castMoneyToShow(props.pago.Capital.cantidad, 'COP')} />
      <InfoCuota key={'Intereses'} titulo={castRubroPago('Intereses')} info={castMoneyToShow(props.pago.Intereses.cantidadPagada, 'COP')} detalle={castMoneyToShow(props.pago.Intereses.cantidad, 'COP')} />
      <InfoCuota key={'Seguros'} titulo={castRubroPago('Seguros')} info={castMoneyToShow(props.pago.Seguros.cantidadPagada, 'COP')} detalle={castMoneyToShow(props.pago.Seguros.cantidad, 'COP')} />
      <InfoCuota key={'Cobranza'} titulo={castRubroPago('Cobranza')} info={castMoneyToShow(props.pago.Cobranza.cantidadPagada, 'COP')} detalle={castMoneyToShow(props.pago.Cobranza.cantidad, 'COP')} />
      <InfoCuota key={'Otros'} titulo={castRubroPago('Otros')} info={castMoneyToShow(props.pago.Otros.cantidadPagada, 'COP')} detalle={castMoneyToShow(props.pago.Otros.cantidad, 'COP')} />
      <InfoCuota key={'Mora'} titulo={castRubroPago('Mora')} info={castMoneyToShow(props.pago.Mora.cantidadPagada, 'COP')} />
      {props.tipo === 'mora' && <FontAwesomeIcon icon={faWarning} color='#FF0000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {!props.loading && props.tipo !== 'pasado' &&
        <button onClick={() => {
          if (props.loading) return
          props.setPago({ pay: props.pago, cuota: props.idx })
          props.setModalVisible(true)
        }}>
          <FontAwesomeIcon icon={faPencil} color='#000000' size='2x' />
        </button>}
    </div>
  )
}

function VerCuotas(props: VerCuotasProps) {

  const fechaActual = new Date().valueOf().toString()
  const [loading, setLoading] = useState<boolean>(false)

  const [pago, setPago] = useState<{ pay: pago, cuota: number } | undefined>(undefined)
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const [cuotasCredito, setCuotasCredito] = useState<{
    cuotaActual: string | undefined, cuotasPasadas: Set<string>, cuotasEnMora: Set<string>, cuotasPendientes: Set<string>
  }>({
    cuotaActual: undefined,
    cuotasPasadas: new Set(),
    cuotasEnMora: new Set(),
    cuotasPendientes: new Set(),
  })

  useEffect(() => {
    const cuotas = getCuotas(props.credito?.pagos ?? [], fechaActual)
    setCuotasCredito({
      cuotaActual: cuotas.cuotaActual?.id,
      cuotasPasadas: new Set([...cuotas.cuotasPasadas.map(i => i.id)]),
      cuotasEnMora: new Set([...cuotas.cuotasEnMora.map(i => i.id)]),
      cuotasPendientes: new Set([...cuotas.cuotasPendientes.map(i => i.id)]),
    })
  }, [props.credito])

  function handleEditarFecha(fecha?: string) {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.credito || !props.token || !pago || !fecha) return
    const validFecha = fecha > pago.pay.fechaLimitePago
    if (!validFecha) toast.error('La fecha de pago debe ser más lejana que la fecha de pago actual')
    if (!validFecha) return
    setLoading(true)
    BackendAdminEditarFechaPago({ payId: pago.pay.id, fecha }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Fecha límite cambiada!')
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }


  function handleEditarRubro(rubro?: {
    rubro: rubroPago
    cantidad: number
    cantidadPagada: number
    detalle?: string
  }) {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.credito || !props.token || !pago || !rubro) return
    const validRubro = rubro.cantidad < rubro.cantidadPagada;
    if (!validRubro) toast.error('No se puede reducir la cantidad a un valor menor de lo que ya se ha pagado')
    if (!validRubro) return
    setLoading(true)
    BackendAdminConfigurarRubro({ payId: pago.pay.id, rubro: rubro.rubro, valor: rubro.cantidad, detalle: rubro.detalle ?? 'Sin detalle' }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Rubro configurado!')
          setModalVisible(false)
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <p className='VerCuotasP'>{`ID crédito: ${props.credito?.id}`}</p>
      <div className="VerCuotas">
        {(props.credito?.pagos ?? []).map((pay, idx) => (
          <ListCuota
            key={`Cuota ${idx}`}
            idx={idx + 1}
            pago={pay}
            tipo={cuotasCredito.cuotaActual === pay.id ? 'actual' : cuotasCredito.cuotasEnMora.has(pay.id) ? 'mora' : cuotasCredito.cuotasPasadas.has(pay.id) ? 'pasado' : 'futuro'}
            setModalVisible={setModalVisible}
            setPago={setPago}
            loading={loading} />
        ))}
        <Modal title='Editar cuota' component={EditarCuota({ pago: pago?.pay, cdtId: props.credito?.id, numCuota: pago?.cuota, handleEditarFecha: handleEditarFecha, handleEditarRubro: handleEditarRubro, loading: loading })} visibility={{ visible: modalVisible, setVisible: setModalVisible }} />
      </div>
    </>

  );
};

export default VerCuotas;