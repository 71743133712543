/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './Modals.css';
import { pago, rubroPago, rubrosPago } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { castDateToString, castStringToDate } from '../Helpers/format';
import Select from 'react-select';
import { castRubroPago } from '../Helpers/casterBack';
import { castMoneyToShow } from '../Helpers/textOutput';
import es from 'date-fns/locale/es';
registerLocale('es', es)

interface EditarCuotaProps {
  numCuota: number | undefined
  cdtId: string | undefined
  pago: pago | undefined
  handleEditarFecha(fecha?: string): void
  handleEditarRubro(rubro?: {
    rubro: rubroPago
    cantidad: number
    cantidadPagada: number
    detalle?: string
  }): void
  loading: boolean
}

function EditarCuota(props: EditarCuotaProps) {

  const [fecha, setFecha] = useState<Date | null>(null)
  const [rubroSelect, setRubroSelect] = useState<{ value: rubroPago, label: string } | null>(null)
  const [rubro, setRubro] = useState<{
    rubro: rubroPago
    cantidad: number
    cantidadPagada: number
    detalle?: string
  } | undefined>(undefined)

  useEffect(() => {
    if (props.pago?.fechaLimitePago) {
      setFecha(castStringToDate(props.pago.fechaLimitePago))
    } else {
      setFecha(null)
    }
  }, [props.pago])

  useEffect(() => {
    if (rubroSelect && props.pago) {
      const rubroPago = props.pago[rubroSelect.value]
      setRubro({
        rubro: rubroSelect.value,
        cantidad: rubroPago.cantidad,
        cantidadPagada: rubroPago.cantidadPagada,
        detalle: rubroPago.detalle,
      })
    } else {
      setRubro(undefined)
    }
  }, [rubroSelect])

  return (
    <div className="EditarCuota">
      <h1>{`Editar cuota ${props.numCuota ?? 0} del crédito ${props.cdtId ?? ''}`}</h1>
      <h2>Fecha límite</h2>
      <DatePicker
        className='EditarCuotaDate'
        locale="es"
        selected={fecha}
        onChange={(date) => setFecha(date)} />
      {props.loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={() => !props.loading ? props.handleEditarFecha(castDateToString(fecha)) : {}}>Cambiar Fecha</button>}
      <h2>Configurar Rubro</h2>
      <Select
        className='EditarCuotaSelect'
        placeholder="Seleccione un rubro"
        defaultValue={rubroSelect}
        onChange={(e) => setRubroSelect(e)}
        options={rubrosPago.filter(r => !['Capital', 'Intereses'].includes(r)).map(r => { return { value: r, label: castRubroPago(r) } })}
      />
      {rubro && (
        <>
          <h2>{`Valor para ${castRubroPago(rubro.rubro)}`}</h2>
          <input
            type="number"
            step={10000}
            min={0}
            value={rubro.cantidad}
            onChange={e => setRubro({ ...rubro, cantidad: Number(e.target.value) })} />
          <h2>{castMoneyToShow(rubro.cantidad, 'COP')}</h2>
          <h2>Notas</h2>
          <input
            type="text"
            placeholder='Detalle del rubro'
            value={rubro.detalle}
            onChange={e => setRubro({ ...rubro, detalle: e.target.value })} />
        </>
      )}
      {props.loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={() => !props.loading ? props.handleEditarRubro(rubro) : {}}>Editar Rubro</button>}
    </div>
  );
};

export default EditarCuota;