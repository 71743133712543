import React, { useState } from 'react';
import './Modals.css';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminRechazarSolicitud, solicitudAdmin } from '../PeopleLoansSDKAdmin';
import { castEstadoSolicitud, getEstadoColor } from '../Helpers/casterBack';
import ModalConfirm from './ModalConfirm';
import { t } from '../traduccion/t';

interface RechazarSolicitudProps {
  solicitud: solicitudAdmin | undefined
  reloadInfo(): void
  token: string | undefined
}


function InfoSolicitud(props: { titulo: string, info: string, colorInfo?: string }) {
  return (
    <div className='DefaultTwoHHorizontal'>
      <h1>{props.titulo}</h1>
      <h2 style={{ color: props.colorInfo ?? '#000000' }}>{props.info}</h2>
    </div>
  )
}

function RechazarSolicitud(props: RechazarSolicitudProps) {

  const [loading, setLoading] = useState<boolean>(false)
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)

  function handleRechazarSolicitud() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.solicitud || !props.token) return
    setLoading(true)
    BackendAdminRechazarSolicitud({ solicitudId: props.solicitud.id }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Solicitud rechazada!')
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="RechazarSolicitud">
      <InfoSolicitud titulo='ID de la solicitud:' info={props.solicitud?.id ?? ''} />
      <InfoSolicitud titulo='Cliente asociado:' info={`${props.solicitud?.cliente ?? ''}: ${props.solicitud?.email ?? ''}`} />
      <InfoSolicitud titulo='Estado actual de la solicitud' info={castEstadoSolicitud(props.solicitud?.estado ?? '')} colorInfo={getEstadoColor(props.solicitud?.estado ?? '')} />
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={() => !loading ? setConfirmVisible(true) : {}}>Rechazar Solicitud</button>}
      <ModalConfirm
        titulo='Asegurese de querer cancelar la solicitud'
        subtitulo='Esta acción no se puede deshacer'
        visibility={{ visible: confirmVisible, setVisible: setConfirmVisible }}
        accionConfirmar={handleRechazarSolicitud}
      />
    </div>
  );
};

export default RechazarSolicitud;