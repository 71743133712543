import React, { useState } from 'react';
import './Modals.css';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminCrearCarro, solicitudAdmin } from '../PeopleLoansSDKAdmin';
import { carro } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import { t } from '../traduccion/t';

interface CrearCarroProps {
  solicitud: solicitudAdmin | undefined
  reloadInfo(): void
  token: string | undefined
}

function InfoSolicitud(props: { titulo: string, info: string, colorInfo?: string }) {
  return (
    <div className='DefaultTwoHHorizontal'>
      <h1>{props.titulo}</h1>
      <h2 style={{ color: props.colorInfo ?? '#000000' }}>{props.info}</h2>
    </div>
  )
}

function InputInfo(props: {
  titulo: string,
  attr:
  'clase' |
  'marca' |
  'linea' |
  'modelo' |
  'placas' |
  'servicio' |
  'color' |
  'declaracionImportacion' |
  'numeroChasis' |
  'numeroMotor' |
  'cilindraje' |
  'carroceria',
  carro: carro, setCarro: React.Dispatch<React.SetStateAction<carro>>
}) {
  return (
    <>
      <h3>{props.titulo}</h3>
      <input
        type="text"
        placeholder={props.titulo}
        value={props.carro[props.attr]}
        onChange={e => props.setCarro({ ...props.carro, [props.attr]: e.target.value })} />
    </>
  )
}

function CrearCarro(props: CrearCarroProps) {

  const [loading, setLoading] = useState<boolean>(false)
  const [carro, setCarro] = useState<carro>(props.solicitud?.carro ?? {
    id: '',
    reqId: props.solicitud?.id ?? '',
    clase: '',
    marca: '',
    linea: '',
    modelo: '',
    placas: '',
    servicio: '',
    color: '',
    declaracionImportacion: '',
    numeroChasis: '',
    numeroMotor: '',
    cilindraje: '',
    carroceria: '',
    nuevo: false,
  })

  function handleCrearCarro() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.solicitud || !props.token) return
    if (carro.clase.trim() === '' ||
      carro.marca.trim() === '' ||
      carro.linea.trim() === '' ||
      carro.modelo.trim() === '' ||
      carro.placas.trim() === '' ||
      carro.servicio.trim() === '' ||
      carro.color.trim() === '' ||
      carro.declaracionImportacion.trim() === '' ||
      carro.numeroChasis.trim() === '' ||
      carro.numeroMotor.trim() === '' ||
      carro.cilindraje.trim() === '' ||
      carro.carroceria.trim() === '') {
      toast.warn('Llena todos los campos, son obligatorios.')
      return
    }
    setLoading(true)
    BackendAdminCrearCarro({
      reqId: props.solicitud.id,
      clase: carro.clase.trim(),
      marca: carro.marca.trim(),
      linea: carro.linea.trim(),
      modelo: carro.modelo.trim(),
      placas: carro.placas.trim(),
      servicio: carro.servicio.trim(),
      color: carro.color.trim(),
      declaracionImportacion: carro.declaracionImportacion.trim(),
      numeroChasis: carro.numeroChasis.trim(),
      numeroMotor: carro.numeroMotor.trim(),
      cilindraje: carro.cilindraje.trim(),
      carroceria: carro.carroceria.trim(),
      nuevo: carro.nuevo,
    }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Carro creado!')
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="CrearCarro">
      <InfoSolicitud titulo='ID de la solicitud:' info={props.solicitud?.id ?? ''} />
      <InfoSolicitud titulo='Cliente asociado:' info={`${props.solicitud?.cliente ?? ''}: ${props.solicitud?.email ?? ''}`} />
      <InputInfo titulo='Clase de vehículo' attr='clase' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Marca del vehículo' attr='marca' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Línea del vehículo' attr='linea' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Modelo del vehículo' attr='modelo' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Placa de vehículo' attr='placas' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Tipo de servicio del vehículo' attr='servicio' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Color del vehículo' attr='color' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Declaración de importación del vehículo' attr='declaracionImportacion' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Número de chasis del vehículo' attr='numeroChasis' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Número de motor del vehículo' attr='numeroMotor' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Cilindraje del vehículo' attr='cilindraje' carro={carro} setCarro={setCarro} />
      <InputInfo titulo='Carrocería del vehículo' attr='carroceria' carro={carro} setCarro={setCarro} />
      <div className='CrearCarroCheckbox'>
        <h3>Vehículo nuevo</h3>
        <input
          type="checkbox"
          checked={carro.nuevo}
          onChange={() => setCarro({ ...carro, nuevo: !carro.nuevo })} />
      </div>
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={handleCrearCarro}>Crear Carro</button>}
    </div>
  );
};

export default CrearCarro;