/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './Modals.css';
import { faRotate, faUser, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminGetClientes, usuarioAdmin } from '../PeopleLoansSDKAdmin';
import { usuario, producto, solicitud, pqrs } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import { castEstadoSolicitud, castPais, getEstadoColor } from '../Helpers/casterBack';
import { castMinutesToShow, castMoneyToShow, castNumberToShow, castTimestampToShow } from '../Helpers/textOutput';
import { t } from '../traduccion/t';

interface VerClienteProps {
  cliente: usuarioAdmin | undefined
  token: string | undefined
}

function ListItemH(props: { titulo: string, info: string }) {
  return (
    <div className='DefaultTwoHHorizontal'>
      <h1>{props.titulo}</h1>
      <h2>{props.info}</h2>
    </div>
  )
}

function ListItemV(props: { titulo: string, info: string, colorInfo?: string, isID?: boolean }) {
  return (
    <div className='DefaultTwoHVertical'>
      <h1 style={{ margin: 10 }}>{props.titulo}</h1>
      <h2 className={props.isID ? 'idhover' : ''} style={props.isID ? {} : { color: props.colorInfo ?? '#000000', margin: 10 }}
        onClick={() => {
          if (!props.isID) return
          navigator.clipboard.writeText(props.info)
          toast.success('ID Copiado!')
        }}>{props.info}</h2>
    </div>
  )
}

function ListCreditoCliente(props: { credito: producto }) {
  return (
    <div className='DefaultColsModal'>
      {props.credito.enMora && <FontAwesomeIcon icon={faWarning} color='#FF0000' size='1x' style={{ alignSelf: 'center', padding: 10 }} />}
      <ListItemV titulo={'ID'} info={props.credito.id} isID={true} />
      <ListItemV titulo={'Siguiente pago'} info={castMoneyToShow(props.credito.pagoMinimo, props.credito.moneda)} colorInfo={props.credito.fechaSiguientePago === '9999999999999' ? '#FF0000' : '#000000'} />
      <ListItemV titulo={'Fecha de pago'} info={castTimestampToShow(props.credito.fechaSiguientePago)} colorInfo={props.credito.fechaSiguientePago === '9999999999999' ? '#FF0000' : '#000000'} />
    </div>
  )
}

function ListSolicitudCliente(props: { solicitud: solicitud }) {
  return (
    <div className='DefaultColsModal'>
      <ListItemV titulo={'Estado'} info={castEstadoSolicitud(props.solicitud.estado)} colorInfo={getEstadoColor(props.solicitud.estado)} />
      <ListItemV titulo={'ID'} info={props.solicitud.id} isID={true} />
      <ListItemV titulo={'Dinero Solicitado'} info={castMoneyToShow(props.solicitud.cantidad, props.solicitud.moneda)} />
      <ListItemV titulo={'Cuotas'} info={props.solicitud.cuotas.toString()} />
      <ListItemV titulo={'Notas'} info={props.solicitud.notas} />
      <ListItemV titulo={'Fecha Solicitud'} info={castTimestampToShow(props.solicitud.fecha)} />
    </div>
  )
}

function ListPQRSCliente(props: { pqrs: pqrs }) {
  try {
    const ultimoMensaje = props.pqrs.mensajes[0]
    if (ultimoMensaje.respuestaSoporte) return (<></>)
    return (
      <div className='DefaultColsModal'>
        <ListItemV titulo={'ID'} info={props.pqrs.id} isID={true} />
        <ListItemV titulo={'Último mensaje'} info={ultimoMensaje.mensaje} />
        <ListItemV titulo={'Fecha último mensaje'} info={castTimestampToShow(ultimoMensaje.fecha)} />
      </div>
    )
  } catch (e) { return (<></>) }
}

function VerCliente(props: VerClienteProps) {

  const [clienteCompleto, setClienteCompleto] = useState<usuario | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  function loadCliente(email?: string) {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (!props.token || !email) return
    setLoading(true)
    BackendAdminGetClientes({ email }, props.token)
      .then(r => {
        if ('status' in r) {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
        } else if (!Array.isArray(r)) {
          setClienteCompleto(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (props.cliente?.id) loadCliente(props.cliente.id)
  }, [props.cliente?.id])

  return (
    <div className="VerCliente">
      {props.cliente?.avatar ? (<img src={props.cliente.avatar} alt='Imagen usuario' />) : (<FontAwesomeIcon icon={faUser} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />)}
      <ListItemH titulo={'Nombre:'} info={props.cliente?.infoBasica.nombre ?? ''} />
      <ListItemH titulo={'Email:'} info={props.cliente?.id ?? ''} />
      <ListItemH titulo={'Teléfono:'} info={props.cliente?.infoBasica.telefono ?? ''} />
      <ListItemH titulo={'Documento:'} info={`${props.cliente?.infoPersonal?.tipoId ?? ''} ${props.cliente?.infoPersonal?.numeroId ?? ''}`} />
      <ListItemH titulo={'Ubicación:'} info={`${props.cliente?.infoPersonal?.ciudad ?? ''}, ${props.cliente?.infoPersonal?.estado ?? ''}, ${castPais(props.cliente?.infoPersonal?.pais ?? '')}`} />
      <ListItemH titulo={'Dirección:'} info={props.cliente?.infoPersonal?.direccion ?? ''} />
      <ListItemH titulo={'Ingresos:'} info={castMoneyToShow(props.cliente?.infoFinanciera?.ingresos ?? 0, props.cliente?.infoFinanciera?.moneda ?? 'COP')} />
      <ListItemH titulo={'Gastos:'} info={castMoneyToShow(props.cliente?.infoFinanciera?.gastos ?? 0, props.cliente?.infoFinanciera?.moneda ?? 'COP')} />
      <ListItemH titulo={'Activos:'} info={castMoneyToShow(props.cliente?.infoFinanciera?.activos ?? 0, props.cliente?.infoFinanciera?.moneda ?? 'COP')} />
      <ListItemH titulo={'Cantidad de solicitudes:'} info={castNumberToShow(props.cliente?.numSolicitudes ?? 0)} />
      <ListItemH titulo={'Dinero Solicitado:'} info={castMoneyToShow(props.cliente?.dineroSolicitudes ?? 0, 'COP')} />
      <ListItemH titulo={'Cantidad de créditos:'} info={castNumberToShow(props.cliente?.numCreditos ?? 0)} />
      <ListItemH titulo={'Dinero Recibido:'} info={castMoneyToShow(props.cliente?.dineroCreditos ?? 0, 'COP')} />
      <ListItemH titulo={'Dinero Pagado:'} info={castMoneyToShow(props.cliente?.dineroPagado ?? 0, 'COP')} />
      <ListItemH titulo={'Pagado Intereses:'} info={castMoneyToShow(props.cliente?.dineroPagadoIntereses ?? 0, 'COP')} />
      <ListItemH titulo={'Pagado Mora:'} info={castMoneyToShow(props.cliente?.dineroPagadoMora ?? 0, 'COP')} />
      <ListItemH titulo={'Tiempo usando la app:'} info={castMinutesToShow(props.cliente?.tiempoActividadApp ?? 0)} />
      <ListItemH titulo={'Cantidad de PQRS:'} info={castNumberToShow(props.cliente?.numPQRS ?? 0)} />
      <h1>Créditos</h1>
      {clienteCompleto?.creditos.map(producto => (<ListCreditoCliente credito={producto} />))}
      <h1>Solicitudes</h1>
      {clienteCompleto?.solicitudes.map(solicitud => (<ListSolicitudCliente solicitud={solicitud} />))}
      <h1>PQRS</h1>
      {clienteCompleto?.pqrs.map(pqrs => (<ListPQRSCliente pqrs={pqrs} />))}
      {!loading && (
        <button onClick={(e) =>
          loadCliente(props.cliente?.id)
        }>
          <FontAwesomeIcon icon={faRotate} color='#FFE900' size='2x' />
        </button>
      )}
    </div>
  );
};

export default VerCliente;