export const es: Record<string, string> = {
  backMalToken: 'Sesión caducada. Inicie sesión nuevamente.',
  backSolicitudNotFound: 'Solicitud no encontrada.',
  backExtractoNotFound: 'Extracto no encontrado.',
  backUsuarioNotFound: 'Usuario no encontrado.',
  backCreditoNotFound: 'Crédito no encontrado.',
  backCuotaNotFound: 'Cuota no encontrada.',
  backCarroNotFound: 'Vehículo no encontrado.',
  backCarroSoloVehicular: 'Debe ser solicitud vehicular.',
  backPagoPSENotFound: 'Pago de PSE no encontrado.',
  backDocumentoNotFound: 'Documento no encontrado.',
  backForbidden: 'Acción no permitida.',
  backBadRequest: 'Error en la solicitud.',
  backTasaNuevaMal: 'La tasa debe ser menor.',
  backCuotasNuevasMal: 'Las cuotas deben ser más.',
  backDocDisponibleFin: 'Documento disponible al terminar los pagos',
  backCuotaNoEspecificada: 'Número de cuota no especificado',
  backRubroEditCredito: 'El rubro indicado se edita con el crédito.',
  backValorRubroMal: 'Debe ser un valor mayor al pagado.',
  backFechaPagoMal: 'Debe ser fecha más lejana.',
  backNoCuotasPago: 'No hay cuotas por pagar.',
  backPagoMaximo: 'No debes pagar tanto dinero.',
  backAceptarMasNo: 'No puede aceptarse un valor mayor al aprobado.',
  backFaltaCarro: 'Falta agregar el carro para la solicitud.',
  backFaltaInfoCliente: 'Falta subir informacion financiera y personal.',
  backSinTasa: 'No hay tasa para el crédito.',
  backEstadoIgual: 'Estado de solicitud actualizado.',
  backNoRechazoAprobada: 'No se puede rechazar una solicitud Aprobada.',
  backNoCancelarAceptada: 'No se puede cancelar una solicitud Aceptada.',
  backSoloPendiente: 'Solicitud solo puede volverse Pendiente.',
  backFaltanDocumentos: 'Falta subir algunos documentos.',
  backNoIncompleta: 'No faltan documentos, está Pendiente.',
  backSoloIncompletaAprobada:
    'Solicitud solo puede volverse Incompleta o Aprobada.',
  backSoloAceptada: 'Solicitud solo puede volverse Aceptada.',
  backSoloRevision: 'Solicitud solo puede ponerse en Revisión.',
  backSoloAceptadaCompletada:
    'Solicitud solo puede mantenerse Aceptada o ser Completada.',
  backNoCambioEstado: 'No se puede cambiar el estado.',
  backSolicitudNoAceptaDocs: 'La solicitud no acepta documentos.',
  backDocNoVa: 'Este documento no va en esta solicitud.',
  backDocVaLuego: 'Este documento se sube luego de aprobado el crédito.',
  backDocYaAprobado: 'Este documento ya está aprobado.',
  backDocFueAntes: 'Este documento se subió antes.',
  backTopeMinimo: 'Valor inferior al mínimo permitido.',
  backTopeMaximo: 'Valor superior al máximo permitido.',
};