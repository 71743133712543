import { psePago, rubroPago, tipoCredito, tipoDoc, usuario } from "@peopleloans/peopleloanssdk";
import { PeopleLoansEnvAdmin } from "./env";
import { productoAdmin, infoGeneralAdmin, solicitudAdmin, usuarioAdmin, pqrsAdmin } from "./interfaces";

type statuses = 200 | 400 | 401 | 402 | 403 | 404 | 500;
type errorResponse = { status: statuses, body: { message?: string, error: any } }
const Backend = (url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', token: string, query?: { [key: string]: string }, body?: any): Promise<{ status: statuses, body: any | undefined }> =>
    new Promise((resolve, reject) => {
        const queryCasted = query ? Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join('&') : undefined
        fetch(`${url}${queryCasted ? `?${queryCasted}` : ''}`, {
            method,
            body: body ? JSON.stringify(body) : undefined,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(async r => {
                let bodyResponse: any = undefined;
                try {
                    bodyResponse = await r.json()
                } catch { }
                resolve({
                    status: r.status as statuses,
                    body: bodyResponse?.results ?? bodyResponse
                })
            })
            .catch(e => reject(e))
    })

export const BackendAdminLogin = (info: {
    email: string
    password: string
}): Promise<string | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Auth', 'POST', '', undefined, info)
            .then(r => resolve(r.status === 200 ? r.body : { ...r }))
            .catch(e => { console.log('BackendAdminLogin:', e); reject(e) })
    })

export const BackendAdminGetCreditos = (token: string): Promise<productoAdmin[] | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Creditos', 'GET', token, undefined, undefined)
            .then(r => resolve(r.status === 200 ? r.body : { ...r }))
            .catch(e => { console.log('BackendAdminGetCreditos:', e); reject(e) })
    })

export const BackendAdminEditarCuotas = (info: {
    cdtId: string
    nuevaTasa?: number
    nuevaCantidadCuotas?: number
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Creditos', 'PUT', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminEditarCuotas:', e); reject(e) })
    })

export const BackendAdminAprobarDocumento = (info: {
    documentoId: string
    aprobar: boolean
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Documentos', 'PUT', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminAprobarDocumento:', e); reject(e) })
    })

export const BackendAdminVerDocumento = (info: {
    email: string
    reqId: string
    nombre: tipoDoc
}, token: string): Promise<string | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Documentos', 'GET', token, info, undefined)
            .then(r => resolve(r.status === 200 ? r.body : { ...r }))
            .catch(e => { console.log('BackendAdminVerDocumento:', e); reject(e) })
    })

export const BackendAdminObtenerInfoGeneral = (token: string): Promise<infoGeneralAdmin | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Info', 'GET', token, undefined, undefined)
            .then(r => resolve(r.status === 200 ? r.body : { ...r }))
            .catch(e => { console.log('BackendAdminObtenerInfoGeneral:', e); reject(e) })
    })

export const BackendAdminResponderPQRS = (info: {
    pqrsId: string
    cliente: string
    mensaje: string
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'PQRS', 'PUT', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminResponderPQRS:', e); reject(e) })
    })

export const BackendAdminGetPQRS = (token: string): Promise<pqrsAdmin[] | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'PQRS', 'GET', token, undefined, undefined)
            .then(r => resolve(r.status === 200 ? r.body : { ...r }))
            .catch(e => { console.log('BackendAdminGetPQRS:', e); reject(e) })
    })

export const BackendAdminGetSolicitudes = (token: string): Promise<solicitudAdmin[] | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Solicitudes', 'GET', token, undefined, undefined)
            .then(r => resolve(r.status === 200 ? r.body : { ...r }))
            .catch(e => { console.log('BackendAdminGetSolicitudes:', e); reject(e) })
    })

export const BackendAdminGetPagosPSE = (token: string): Promise<psePago[] | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'PSE', 'GET', token, undefined, undefined)
            .then(r => resolve(r.status === 200 ? r.body : { ...r }))
            .catch(e => { console.log('BackendAdminGetPagosPSE:', e); reject(e) })
    })

export const BackendAdminCompletarSolicitud = (info: {
    solicitudId: string
    pdf: string
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Solicitudes', 'POST', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminCompletarSolicitud:', e); reject(e) })
    })

export const BackendAdminAprobarSolicitud = (info: {
    solicitudId: string
    tasa: number
    cuotas: number
    valor: number
    seguro: number
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Solicitudes', 'PUT', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminAprobarSolicitud:', e); reject(e) })
    })

export const BackendAdminRechazarSolicitud = (info: {
    solicitudId: string
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Solicitudes', 'DELETE', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminRechazarSolicitud:', e); reject(e) })
    })

export const BackendAdminCambiarTasa = (info: {
    credito: tipoCredito
    tasa: number
    tasaMora: number
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Tasas', 'PUT', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminCambiarTasa:', e); reject(e) })
    })

export const BackendAdminCambiarTope = (info: {
    credito: tipoCredito
    minimo: number
    maximo: number
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Topes', 'PUT', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminCambiarTope:', e); reject(e) })
    })

export const BackendAdminGetClientes = (info: { email?: string }, token: string): Promise<usuarioAdmin[] | usuario | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'User', 'GET', token, info, undefined)
            .then(r => resolve(r.status === 200 ? r.body : { ...r }))
            .catch(e => { console.log('BackendAdminGetClientes:', e); reject(e) })
    })

export const BackendAdminConfigurarRubro = (info: {
    payId: string
    rubro: rubroPago
    valor: number
    detalle: string
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Pago', 'POST', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminConfigurarRubro:', e); reject(e) })
    })

export const BackendAdminEditarFechaPago = (info: {
    payId: string
    fecha: string
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Pago', 'PUT', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminEditarFechaPago:', e); reject(e) })
    })

export const BackendAdminCrearCarro = (info: {
    reqId: string
    clase: string
    marca: string
    linea: string
    modelo: string
    placas: string
    servicio: string
    color: string
    declaracionImportacion: string
    numeroChasis: string
    numeroMotor: string
    cilindraje: string
    carroceria: string
    nuevo: boolean
}, token: string): Promise<undefined | errorResponse> =>
    new Promise((resolve, reject) => {
        Backend(PeopleLoansEnvAdmin.apiUrl + 'Carro', 'POST', token, undefined, info)
            .then(r => resolve(r.status === 200 ? undefined : { ...r }))
            .catch(e => { console.log('BackendAdminCrearCarro:', e); reject(e) })
    })