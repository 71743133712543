import React, { useEffect, useState } from 'react';
import './Modals.css';
import { castNombreDocumento } from '../Helpers/casterBack';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminAprobarDocumento, solicitudAdmin } from '../PeopleLoansSDKAdmin';
import { documento, getDocumentosRequeridos } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import ModalConfirm from './ModalConfirm';
import { t } from '../traduccion/t';
interface VerDocumentosProps {
  solicitud: solicitudAdmin | undefined
  token: string | undefined
}

function abrirPDF(url?: string, token?: string) {
  if (!url || !token) return;
  toast.warn('Abriendo PDF, espera...')
  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
    .then(async r => {
      let bodyResponse: any = undefined;
      try {
        bodyResponse = await r.json()
      } catch { }
      return ({
        status: r.status,
        body: bodyResponse?.results ?? bodyResponse
      })
    })
    .then(r => {
      if (r.status === 404) {
        toast.error(t('es', r.body?.message ?? 'Error desconocido'))
      } else {
        const byteCharacters = atob(r.body);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        window.open(
          URL.createObjectURL(new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' })),
          '_blank');
      }
    })
    .catch(e => {
      toast.error(`Error: ${e}`)
      console.log(e)
    })
}

function InfoDocumento(props: { titulo: string, info?: string, link?: { text: string, url: string, token: string } }) {
  return (
    <div className='DefaultTwoHVertical'>
      <h1 style={{ margin: 10 }}>{props.titulo}</h1>
      {props.info && <h2 style={{ margin: 10 }}>{props.info}</h2>}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {props.link && <a onClick={() => abrirPDF(props.link?.url, props.link?.token)}>{props.link.text}</a>}
    </div>
  )
}

interface ListDocumentoProps {
  documento?: documento
  nombre: string
  docsAceptados: Set<string>
  docsRechazados: Set<string>
  setConfirmVisibleAceptar: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmVisibleRechazar: React.Dispatch<React.SetStateAction<boolean>>
  setDocumento: React.Dispatch<React.SetStateAction<documento | undefined>>
  token: string
}

function ListDocumento(props: ListDocumentoProps) {
  return (
    <div className='DefaultColsModal'>
      {(props.documento?.aprobado || props.docsAceptados.has(props.nombre)) && <FontAwesomeIcon icon={faCheck} color='#00CC00' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {props.docsRechazados.has(props.nombre) && <FontAwesomeIcon icon={faTimes} color='#FF0000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      <InfoDocumento titulo={'Documento'} info={castNombreDocumento(props.nombre)} />
      <InfoDocumento titulo={'PDF'} link={{ text: props.documento ? 'Documento PDF' : '', url: props.documento?.url ?? '', token: props.token }} />
      {(props.documento?.aprobado || props.docsAceptados.has(props.nombre)) && <FontAwesomeIcon icon={faCheck} color='#00CC00' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {props.docsRechazados.has(props.nombre) && <FontAwesomeIcon icon={faTimes} color='#FF0000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {(props.documento && !props.documento?.aprobado && !props.docsAceptados.has(props.nombre) && !props.docsRechazados.has(props.nombre)) && (
        <button onClick={() => {
          props.setDocumento(props.documento)
          props.setConfirmVisibleAceptar(true)
        }}>
          <FontAwesomeIcon icon={faCheck} color='#00FF00' size='2x' />
        </button>
      )}
      {(props.documento && !props.documento?.aprobado && !props.docsAceptados.has(props.nombre) && !props.docsRechazados.has(props.nombre)) && (
        <button onClick={() => {
          props.setDocumento(props.documento)
          props.setConfirmVisibleRechazar(true)
        }}>
          <FontAwesomeIcon icon={faTimes} color='#FF0000' size='2x' />
        </button>
      )}
    </div>
  )
}

function VerDocumentos(props: VerDocumentosProps) {

  const [loading, setLoading] = useState<boolean>(false)

  const [docsAceptados, setDocsAceptados] = useState<Set<string>>(new Set())
  const [docsRechazados, setDocsRechazados] = useState<Set<string>>(new Set())

  const [documento, setDocumento] = useState<documento | undefined>(undefined)
  const [confirmVisibleAceptar, setConfirmVisibleAceptar] = useState<boolean>(false)
  const [confirmVisibleRechazar, setConfirmVisibleRechazar] = useState<boolean>(false)

  function handleAceptarDoc() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.solicitud || !props.token || !documento) return
    setLoading(true)
    BackendAdminAprobarDocumento({ documentoId: documento.id, aprobar: true }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Documento aprobado!')
          setDocsAceptados(prev => new Set([...prev, documento.nombre]))
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  function handleRechazarDoc() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.solicitud || !props.token || !documento) return
    setLoading(true)
    BackendAdminAprobarDocumento({ documentoId: documento.id, aprobar: false }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Documento rechazado!')
          setDocsRechazados(prev => new Set([...prev, documento.nombre]))
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => {
        setDocumento(undefined)
        setLoading(false)
      })
  }

  useEffect(() => {
    setDocsAceptados(new Set())
    setDocsRechazados(new Set())
  }, [props.solicitud])

  return (
    <div className="VerDocumentos">
      <p>{`ID solicitud: ${props.solicitud?.id}`}</p>
      <h1 style={{ alignSelf: 'center', textAlign: 'center' }}>Documentos solicitados</h1>
      <h1 style={{ alignSelf: 'center', textAlign: 'center' }}>Documentos para firmar</h1>
      <div className='VerDocumentosCol'>
        {props.solicitud?.tipo ? (
          [...getDocumentosRequeridos(props.solicitud.tipo).pre].map(doc => (
            <ListDocumento key={doc} nombre={doc}
              documento={props.solicitud?.documentosPre[doc]}
              docsAceptados={docsAceptados}
              docsRechazados={docsRechazados}
              setDocumento={setDocumento}
              setConfirmVisibleAceptar={setConfirmVisibleAceptar}
              setConfirmVisibleRechazar={setConfirmVisibleRechazar}
              token={props.token ?? ''} />
          ))
        ) : (<></>)}
      </div>
      <div className='VerDocumentosCol'>
        {props.solicitud?.tipo ? (
          [...getDocumentosRequeridos(props.solicitud.tipo).post].map(doc => (
            <ListDocumento key={doc} nombre={doc}
              documento={props.solicitud?.documentosPost[doc]}
              docsAceptados={docsAceptados}
              docsRechazados={docsRechazados}
              setDocumento={setDocumento}
              setConfirmVisibleAceptar={setConfirmVisibleAceptar}
              setConfirmVisibleRechazar={setConfirmVisibleRechazar}
              token={props.token ?? ''} />
          ))
        ) : (<></>)}
      </div>
      <ModalConfirm
        titulo={`¿Aceptar el documento ${castNombreDocumento(documento?.nombre ?? '')}?`}
        subtitulo='Esta acción no se puede deshacer'
        visibility={{ visible: confirmVisibleAceptar, setVisible: setConfirmVisibleAceptar }}
        accionConfirmar={handleAceptarDoc}
      />
      <ModalConfirm
        titulo={`¿Rechazar el documento ${castNombreDocumento(documento?.nombre ?? '')}?`}
        subtitulo='Esta acción no se puede deshacer'
        visibility={{ visible: confirmVisibleRechazar, setVisible: setConfirmVisibleRechazar }}
        accionConfirmar={handleRechazarDoc}
      />
    </div>
  );
};

export default VerDocumentos;