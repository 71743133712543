import React, { useState } from 'react';
import './AdminPanel.css';
import { infoGeneralAdmin } from '../PeopleLoansSDKAdmin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faComputer, faDownload, faFileAlt, faMoneyBill, faPencil, faQuestionCircle, faRotate, faUser } from '@fortawesome/free-solid-svg-icons';
import { castNumberToShow, castMinutesToShow, castTasaToShow, castMoneyToShow } from '../Helpers/textOutput';
import { castTipoCredito } from '../Helpers/casterBack';
import Modal from '../Modals/Modal';
import EditarTasa from '../Modals/EditarTasa'
import { tasa, tope } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import EditarTopes from '../Modals/EditarTopes';

interface DashboardMainProps {
  info: infoGeneralAdmin
  token: string | undefined
  reloadDashboard(): void
}

interface DashboardProps {
  info: infoGeneralAdmin
  token: string | undefined
}

function DashboardApp(props: DashboardProps) {
  return (
    <div className="DashboardApp">
      <div className='DefaultCols'>
        <FontAwesomeIcon icon={faDownload} color='#FFE900' size='2x' />
        <h1>{castNumberToShow(props.info.descargasApp)}</h1>
      </div>
      <div className='DefaultCols'>
        <FontAwesomeIcon icon={faComputer} color='#FFE900' size='2x' />
        <h1>{castNumberToShow(props.info.visitasWeb)}</h1>
      </div>
      <div className='DefaultCols'>
        <FontAwesomeIcon icon={faClock} color='#FFE900' size='2x' />
        <h1>{castMinutesToShow(props.info.tiempoActividadApp)}</h1>
      </div>
    </div>
  );
}

function DashboardBD(props: DashboardProps) {
  return (
    <div className="DashboardBD">
      <div className='DefaultCols'>
        <FontAwesomeIcon icon={faUser} color='#FFE900' size='2x' />
        <h1>{castNumberToShow(props.info.clientes)}</h1>
      </div>
      <div className='DefaultCols'>
        <FontAwesomeIcon icon={faFileAlt} color='#FFE900' size='2x' />
        <h1>{castNumberToShow(props.info.solicitudes)}</h1>
      </div>
      <div className='DefaultCols'>
        <FontAwesomeIcon icon={faMoneyBill} color='#FFE900' size='2x' />
        <h1>{castNumberToShow(props.info.creditos)}</h1>
      </div>
      <div className='DefaultCols'>
        <FontAwesomeIcon icon={faQuestionCircle} color='#FFE900' size='2x' />
        <h1>{castNumberToShow(props.info.pqrs)}</h1>
      </div>
    </div>
  );
}

interface DashboardTasasProps extends DashboardProps {
  setTasaAEditar: React.Dispatch<React.SetStateAction<tasa | undefined>>
  setVerModalTasas: React.Dispatch<React.SetStateAction<boolean>>
}

function DashboardTasas(props: DashboardTasasProps) {
  return (
    <div className="DashboardTasas">
      {props.info.tasas.map(tasa => {
        return (
          <div className='DefaultCols' key={`Tasa${tasa.id}`}>
            <h2>{castTipoCredito(tasa.id)}</h2>
            <h3>{`Tasa (EA): ${castTasaToShow(tasa.tasa)}`}</h3>
            <h3>{`Tasa Mora (EA): ${castTasaToShow(tasa.tasaMora)}`}</h3>
            {/* <h3>{castTimestampToShow(tasa.fecha)}</h3> */}
            <button onClick={() => {
              props.setTasaAEditar(tasa)
              props.setVerModalTasas(true)
            }}>
              <FontAwesomeIcon icon={faPencil} color='#000000' />
            </button>
          </div>
        )
      })}
    </div>
  );
}

interface DashboardTopesProps extends DashboardProps {
  setTopeAEditar: React.Dispatch<React.SetStateAction<tope | undefined>>
  setVerModalTopes: React.Dispatch<React.SetStateAction<boolean>>
}

function DashboardTopes(props: DashboardTopesProps) {
  return (
    <div className="DashboardTopes">
      {props.info.topes.map(tope => {
        return (
          <div className='DefaultCols' key={`Tope${tope.id}`}>
            <h2>{castTipoCredito(tope.id)}</h2>
            <h3>{`Mínimo: ${castMoneyToShow(tope.minimo, 'COP')}`}</h3>
            <h3>{`Máximo: ${castMoneyToShow(tope.maximo, 'COP')}`}</h3>
            <button onClick={() => {
              props.setTopeAEditar(tope)
              props.setVerModalTopes(true)
            }}>
              <FontAwesomeIcon icon={faPencil} color='#000000' />
            </button>
          </div>
        )
      })}
    </div>
  );
}

function Dashboard(props: DashboardMainProps) {
  const [verModalTasas, setVerModalTasas] = useState<boolean>(false)
  const [tasaAEditar, setTasaAEditar] = useState<tasa | undefined>(undefined)

  const [verModalTopes, setVerModalTopes] = useState<boolean>(false)
  const [topeAEditar, setTopeAEditar] = useState<tope | undefined>(undefined)

  function reloadTasas() {
    setVerModalTasas(false)
    setTasaAEditar(undefined)
    props.reloadDashboard()
  }

  function reloadTopes() {
    setVerModalTopes(false)
    setTopeAEditar(undefined)
    props.reloadDashboard()
  }

  return (
    <div className="Dashboard">
      <DashboardApp token={props.token} info={props.info} />
      <DashboardBD token={props.token} info={props.info} />
      <DashboardTasas token={props.token} info={props.info} setTasaAEditar={setTasaAEditar} setVerModalTasas={setVerModalTasas} />
      <DashboardTopes token={props.token} info={props.info} setTopeAEditar={setTopeAEditar} setVerModalTopes={setVerModalTopes}/>
      <button onClick={(e) =>
        props.reloadDashboard()
      }>
        <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' />
      </button>
      <Modal title='Editar Tasa' component={EditarTasa({ tasa: tasaAEditar, token: props.token, reloadTasas: reloadTasas })} visibility={{ visible: verModalTasas, setVisible: setVerModalTasas }} />
      <Modal title='Editar Topes' component={EditarTopes({ tope: topeAEditar, token: props.token, reloadTopes: reloadTopes })} visibility={{ visible: verModalTopes, setVisible: setVerModalTopes }} />
    </div>
  );
}

export default Dashboard;