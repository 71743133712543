import React, { useEffect, useState } from 'react';
import './Modals.css';
import { tasa } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import { castTipoCredito } from '../Helpers/casterBack';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminCambiarTasa } from '../PeopleLoansSDKAdmin';
import { t } from '../traduccion/t';

interface EditarTasaProps {
  tasa: tasa | undefined
  reloadTasas(): void
  token: string | undefined
}

function EditarTasa(props: EditarTasaProps) {

  const [valTasa, setValTasa] = useState<number>(props.tasa?.tasa ?? 0)
  const [valTasaMora, setValTasaMora] = useState<number>(props.tasa?.tasaMora ?? 0)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setValTasa(props.tasa?.tasa ?? 0)
    setValTasaMora(props.tasa?.tasaMora ?? 0)
  }, [props.tasa])

  function handleCambiarTasa() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.tasa || !props.token) return
    const validTasa = valTasa > 0
    const validTasaMora = valTasaMora > 0
    if (!validTasa) toast.error('La tasa debe ser positiva')
    if (!validTasaMora) toast.error('La tasa de mora debe ser positiva')
    if (!validTasa || !validTasaMora) return
    setLoading(true)
    BackendAdminCambiarTasa({ credito: props.tasa.id, tasa: valTasa, tasaMora: valTasaMora }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Tasas actualizadas!')
          props.reloadTasas()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="EditarTasa">
      <h1>Crédito a editar: {castTipoCredito(props.tasa?.id ?? '')}</h1>
      <h2>Tasa del crédito (EA)</h2>
      <input
        type="number"
        step={0.1}
        min={0}
        value={valTasa}
        onChange={e => setValTasa(Number(e.target.value))} />
      <h2>Tasa de mora (EA)</h2>
      <input
        type="number"
        step={0.1}
        min={0}
        value={valTasaMora}
        onChange={e => setValTasaMora(Number(e.target.value))} />
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={handleCambiarTasa}>Guardar</button>}
    </div>
  );
};

export default EditarTasa;