/* eslint-disable react-hooks/exhaustive-deps */
import './AdminPanel.css';
import { productoAdmin } from '../PeopleLoansSDKAdmin';
import { lista } from './AdminPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendarAlt, faRotate } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { registerLocale } from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts'
import { castMoneyToShow, castTimestampToShow } from '../Helpers/textOutput';
import { toast } from 'react-toastify';
import { psePago } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
registerLocale('es', es)

interface ReporteMainProps {
  creditos: productoAdmin[]
  pagos: psePago[]
  llenarLista(listaElegida: lista | undefined, token: string | undefined, forceReload?: boolean): void
  token: string | undefined
}

type reporte =
  'Dinero desembolsado' |
  'Dinero recibido total' |
  'Dinero recibido en intereses' |
  'Dinero recibido a capital' |
  'Dinero recibido por mora' |
  'Dinero recibido en seguros' |
  'Dinero recibido en cobranza' |
  'Otros dineros recibidos'

const reportesList: reporte[] = [
  'Dinero desembolsado',
  'Dinero recibido total',
  'Dinero recibido en intereses',
  'Dinero recibido a capital',
  'Dinero recibido por mora',
  'Dinero recibido en seguros',
  'Dinero recibido en cobranza',
  'Otros dineros recibidos',
]

const getInfoDate = (timestamp: string): string => {
  // Agrupar por meses
  // Forzar fecha a las 00:00:00:0000 del 1 día del mes
  const fecha = new Date(parseInt(timestamp))
  fecha.setUTCMilliseconds(0)
  fecha.setUTCSeconds(0)
  fecha.setUTCMinutes(0)
  fecha.setUTCHours(0)
  fecha.setUTCDate(1)
  return fecha.valueOf().toString();
}

const generarInfo = (
  lists: {
    creditos: productoAdmin[],
    pagos: psePago[]
  },
  reporte: reporte,
): { [key: string]: number } => {
  const info: { [key: string]: number } = {};
  const fechaActual = new Date();
  let fechaIteracion = new Date(1704067200000); // 1 enero 2024 00:00:00:0000
  while (fechaIteracion <= fechaActual) {
    info[fechaIteracion.valueOf().toString()] = 0
    fechaIteracion.setUTCMonth(fechaIteracion.getUTCMonth() + 1);
  }

  switch (reporte) {
    case 'Dinero desembolsado':
      lists.creditos.forEach(cdt => {
        info[getInfoDate(cdt.fecha)] += cdt.cantidad
      })
      return info
    case 'Dinero recibido total':
      lists.pagos.forEach(pse => {
        info[getInfoDate(pse.fecha)] += pse.valor;
      })
      return info
    case 'Dinero recibido en intereses':
      lists.pagos.forEach(pse => {
        pse.cuotas.forEach(pago => {
          info[getInfoDate(pse.fecha)] += pago.valores.Intereses;
        })
      })
      return info
    case 'Dinero recibido a capital':
      lists.pagos.forEach(pse => {
        pse.cuotas.forEach(pago => {
          info[getInfoDate(pse.fecha)] += pago.valores.Capital;
        })
      })
      return info
    case 'Dinero recibido por mora':
      lists.pagos.forEach(pse => {
        pse.cuotas.forEach(pago => {
          info[getInfoDate(pse.fecha)] += pago.valores.Mora;
        })
      })
      return info
    case 'Dinero recibido en seguros':
      lists.pagos.forEach(pse => {
        pse.cuotas.forEach(pago => {
          info[getInfoDate(pse.fecha)] += pago.valores.Seguros;
        })
      })
      return info
    case 'Dinero recibido en cobranza':
      lists.pagos.forEach(pse => {
        pse.cuotas.forEach(pago => {
          info[getInfoDate(pse.fecha)] += pago.valores.Cobranza;
        })
      })
      return info
    case 'Otros dineros recibidos':
      lists.pagos.forEach(pse => {
        pse.cuotas.forEach(pago => {
          info[getInfoDate(pse.fecha)] += pago.valores.Otros;
        })
      })
      return info
    default:
      return info
  }
}

const getInfo = (
  info: { [key: string]: number },
  start: Date,
  end: Date
): { info: number[][], total: number } => {
  let total = 0
  let fechaIteracion = new Date(Date.UTC(start.getFullYear(), start.getMonth(), 1, 0, 0, 0, 0))
  const data: number[][] = []
  while (fechaIteracion.valueOf() <= new Date(Date.UTC(end.getFullYear(), end.getMonth(), 1, 0, 0, 0, 0)).valueOf()) {
    data.push([fechaIteracion.valueOf(), info[fechaIteracion.valueOf().toString()]])
    total += info[fechaIteracion.valueOf().toString()]
    fechaIteracion.setUTCMonth(fechaIteracion.getUTCMonth() + 1);
  }
  return { info: data, total }
}

function Reporte(props: ReporteMainProps) {

  const [fechaInicio, setFechaInicio] = useState<Date | null>(null)
  const [fechaFin, setFechaFin] = useState<Date | null>(null)
  const [reporteSelect, setReporteSelect] = useState<{ value: reporte, label: reporte } | null>(null)

  const [chartInfo, setChartInfo] = useState<ApexAxisChartSeries>([]);
  const charOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      background: '#FFFFFF'
    },
    colors: ['#000000'],
    grid: {
      borderColor: '#FFE900',
      position: 'back',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: 'small',
          fontWeight: 'bold',
        },
        formatter: (i) => castTimestampToShow(i).split(' ')[0].split('/').slice(1).join('/')
      }
    },
    yaxis: {
      labels: {
        formatter: (i) => castMoneyToShow(i, 'COP'),
        style: {
          fontSize: 'small',
          fontWeight: 'bold',
        }
      }
    }
  }

  const [info, setInfo] = useState<{ [key: string]: number }>({})
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    if (reporteSelect?.value) {
      setInfo({ ...generarInfo({ creditos: props.creditos, pagos: props.pagos }, reporteSelect.value) })
    } else {
      setInfo({})
    }
  }, [props.creditos, props.pagos, reporteSelect?.value])

  useEffect(() => {
    if (fechaInicio && fechaFin) {
      if (fechaInicio > fechaFin) { toast.warn('La fecha inicial debe ser menor o igual a la final'); return; }
      if (reporteSelect?.value) {
        const data = getInfo(info, fechaInicio, fechaFin)
        setChartInfo([{
          name: reporteSelect?.label ?? '',
          data: data.info
        }])
        setTotal(data.total)
      }
    }
  }, [fechaInicio, fechaFin])

  useEffect(() => {
    if (fechaInicio && fechaFin) {
      if (fechaInicio > fechaFin) { toast.warn('La fecha inicial debe ser menor o igual a la final'); return; }
      if (reporteSelect?.value) {
        const data = getInfo(info, fechaInicio, fechaFin)
        setChartInfo([{
          name: reporteSelect?.label ?? '',
          data: data.info
        }])
        setTotal(data.total)
      }
    }
  }, [info])

  return (
    <div className="Reporte">
      <div className='DefaultCols'>
        <FontAwesomeIcon icon={faCalendarAlt} size='2x' color='#000000' style={{ padding: 10 }} />
        <DatePicker
          showMonthYearPicker
          dateFormat="MM/yyyy"
          className='ReporteDate'
          locale="es"
          minDate={new Date(1704067200000)}
          maxDate={new Date()}
          selected={fechaInicio}
          onChange={(date) => setFechaInicio(date)} />
        <FontAwesomeIcon icon={faArrowRight} size='2x' color='#000000' style={{ padding: 10 }} />
        <FontAwesomeIcon icon={faCalendarAlt} size='2x' color='#000000' style={{ padding: 10 }} />
        <DatePicker
          showMonthYearPicker
          dateFormat="MM/yyyy"
          className='ReporteDate'
          locale="es"
          minDate={new Date(1704067200000)}
          maxDate={new Date()}
          selected={fechaFin}
          onChange={(date) => setFechaFin(date)} />
        <Select
          className='ReporteSelect'
          placeholder="Seleccione un reporte"
          defaultValue={reporteSelect}
          onChange={(e) => setReporteSelect(e)}
          options={reportesList.map(r => { return { value: r, label: r } })}
        />
        <h1>{`Total: ${castMoneyToShow(total, 'COP')}`}</h1>
        <button onClick={(e) =>
          props.llenarLista('Reportes', props.token, true)
        } style={{ marginLeft: 100 }}>
          <FontAwesomeIcon icon={faRotate} color='#000000' size='1x' />
        </button>
      </div>
      <Chart
        key={JSON.stringify(info)}
        options={charOptions}
        series={chartInfo}
        type="line"
        width="100%"
        height="85%"
      />
    </div>
  );
}

export default Reporte;