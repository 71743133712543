import React from 'react';
import './AdminPanel.css';
import { lista } from './AdminPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faFileAlt, faMoneyBill, faQuestionCircle, faUser } from '@fortawesome/free-solid-svg-icons';

interface ButtonsProps {
  seleccion: { get: lista | undefined, set: React.Dispatch<React.SetStateAction<lista | undefined>> }
  token: string | undefined
}

function Buttons(props: ButtonsProps) {
  return (
    <div className="Buttons">
      <button className={props.seleccion.get === 'Clientes' ? 'active' : ''}
        onClick={() => props.seleccion.set('Clientes')}>
        <FontAwesomeIcon icon={faUser} color='#000000' style={{ marginRight: 10 }} />
        Clientes
      </button>
      <button className={props.seleccion.get === 'Solicitudes' ? 'active' : ''}
        onClick={() => props.seleccion.set('Solicitudes')}>
        <FontAwesomeIcon icon={faFileAlt} color='#000000' style={{ marginRight: 10 }} />
        Solicitudes
      </button>
      <button className={props.seleccion.get === 'Creditos' ? 'active' : ''}
        onClick={() => props.seleccion.set('Creditos')}>
        <FontAwesomeIcon icon={faMoneyBill} color='#000000' style={{ marginRight: 10 }} />
        Créditos
      </button>
      <button className={props.seleccion.get === 'PQRS' ? 'active' : ''}
        onClick={() => props.seleccion.set('PQRS')}>
        <FontAwesomeIcon icon={faQuestionCircle} color='#000000' style={{ marginRight: 10 }} />
        PQRS
      </button>
      <button className={props.seleccion.get === 'Reportes' ? 'active' : ''}
        onClick={() => props.seleccion.set('Reportes')}>
        <FontAwesomeIcon icon={faChartLine} color='#000000' style={{ marginRight: 10 }} />
        Reportes
      </button>
    </div>
  );
}

export default Buttons;