/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './AdminPanel.css';
import Dashboard from './Dashboard';
import Buttons from './Buttons';
import List from './List';
import { BackendAdminGetClientes, BackendAdminGetCreditos, BackendAdminGetPagosPSE, BackendAdminGetPQRS, BackendAdminGetSolicitudes, BackendAdminObtenerInfoGeneral, infoGeneralAdmin, pqrsAdmin, productoAdmin, solicitudAdmin, usuarioAdmin } from '../PeopleLoansSDKAdmin';
import { toast } from 'react-toastify';
import Reporte from './Reporte';
import { t } from '../traduccion/t';
import { psePago } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'

export type lista = 'Clientes' | 'Solicitudes' | 'Creditos' | 'PQRS' | 'Reportes'
interface AdminPanelProps {
  token: { get: string | undefined, set: React.Dispatch<React.SetStateAction<string | undefined>> }
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultInfoGeneral: infoGeneralAdmin = {
  clientes: 0,
  creditos: 0,
  solicitudes: 0,
  descargasApp: 0,
  visitasWeb: 0,
  tiempoActividadApp: 0,
  pqrs: 0,
  tasas: [],
  topes: [],
}

function AdminPanel(props: AdminPanelProps) {
  const [infoGeneral, setInfoGeneral] = useState<infoGeneralAdmin>(defaultInfoGeneral)
  const [clientes, setClientes] = useState<usuarioAdmin[]>([])
  const [solicitudes, setSolicitudes] = useState<solicitudAdmin[]>([])
  const [creditos, setCreditos] = useState<productoAdmin[]>([])
  const [pqrs, setPQRS] = useState<pqrsAdmin[]>([])
  const [pagos, setPagos] = useState<psePago[]>([])
  const [listaSeleccionada, setListaSeleccionada] = useState<lista | undefined>(undefined)

  function reloadDashboard() {
    setListaSeleccionada(undefined)
    if (props.token.get) {
      props.setLoading(true)
      BackendAdminObtenerInfoGeneral(props.token.get)
        .then(r => {
          if ('status' in r) {
            toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          } else {
            setInfoGeneral(r)
          }
        })
        .catch(e => {
          toast.error(`Error: ${e}`)
          console.log(e)
        })
        .finally(() => props.setLoading(false))
    }
  }

  function llenarLista(listaElegida: lista | undefined, token: string | undefined, forceReload = false) {
    if (listaElegida && token) {
      switch (listaElegida) {
        case 'Clientes':
          if (clientes.length > 0 && !forceReload) return
          props.setLoading(true)
          BackendAdminGetClientes({}, token)
            .then(r => {
              if ('status' in r) {
                toast.error(t('es', r.body?.message ?? 'Error desconocido'))
              } else if (Array.isArray(r)) {
                setClientes(r)
              }
            })
            .catch(e => {
              toast.error(`Error: ${e}`)
              console.log(e)
            })
            .finally(() => props.setLoading(false))
          break
        case 'Solicitudes':
          if (solicitudes.length > 0 && !forceReload) return
          props.setLoading(true)
          BackendAdminGetSolicitudes(token)
            .then(r => {
              if ('status' in r) {
                toast.error(t('es', r.body?.message ?? 'Error desconocido'))
              } else {
                setSolicitudes(r)
              }
            })
            .catch(e => {
              toast.error(`Error: ${e}`)
              console.log(e)
            })
            .finally(() => props.setLoading(false))
          break
        case 'Creditos':
          if (creditos.length > 0 && !forceReload) return
          props.setLoading(true)
          BackendAdminGetCreditos(token)
            .then(r => {
              if ('status' in r) {
                toast.error(t('es', r.body?.message ?? 'Error desconocido'))
              } else {
                setCreditos(r)
              }
            })
            .catch(e => {
              toast.error(`Error: ${e}`)
              console.log(e)
            })
            .finally(() => props.setLoading(false))
          break
        case 'PQRS':
          if (pqrs.length > 0 && !forceReload) return
          props.setLoading(true)
          BackendAdminGetPQRS(token)
            .then(r => {
              if ('status' in r) {
                toast.error(t('es', r.body?.message ?? 'Error desconocido'))
              } else {
                setPQRS(r)
              }
            })
            .catch(e => {
              toast.error(`Error: ${e}`)
              console.log(e)
            })
            .finally(() => props.setLoading(false))
          break
        case 'Reportes':
          if (creditos.length > 0 && pagos.length > 0 && !forceReload) return
          props.setLoading(true)
          Promise.all([
            BackendAdminGetCreditos(token),
            BackendAdminGetPagosPSE(token),
          ])
            .then(([cred, pays]) => {
              if ('status' in cred) {
                toast.error(t('es', cred.body?.message ?? 'Error desconocido'))
              } else {
                setCreditos(cred)
              }
              if ('status' in pays) {
                toast.error(t('es', pays.body?.message ?? 'Error desconocido'))
              } else {
                setPagos(pays)
              }
            })
            .catch(e => {
              toast.error(`Error: ${e}`)
              console.log(e)
            })
            .finally(() => props.setLoading(false))
      }
    }
  }

  useEffect(() => {
    reloadDashboard()
  }, [props.token.get])

  useEffect(() => {
    llenarLista(listaSeleccionada, props.token.get)
  }, [listaSeleccionada])

  return (
    <div className='AdminPanel'>
      {props.token.get === undefined ? (<></>) : (
        <>
          <Dashboard token={props.token.get} info={infoGeneral} reloadDashboard={reloadDashboard} />
          <Buttons token={props.token.get} seleccion={{ get: listaSeleccionada, set: setListaSeleccionada }} />
          {listaSeleccionada !== 'Reportes' && <List token={props.token.get} listaSeleccionada={listaSeleccionada} clientes={clientes} creditos={creditos} solicitudes={solicitudes} pqrs={pqrs} llenarLista={llenarLista} />}
          {listaSeleccionada === 'Reportes' && <Reporte token={props.token.get} creditos={creditos} pagos={pagos} llenarLista={llenarLista} />}
        </>
      )}
    </div>)
}

export default AdminPanel;
