import React, { useState } from 'react';
import './Modals.css';
import { faPaperPlane, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminResponderPQRS, pqrsAdmin } from '../PeopleLoansSDKAdmin';
import { mensaje } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import { castTimestampToShow } from '../Helpers/textOutput';
import { t } from '../traduccion/t';

interface VerMensajesProps {
  pqrs: pqrsAdmin | undefined
  reloadInfo(): void
  token: string | undefined
}

function InfoMensaje(props: { mensaje: mensaje }) {
  return (
    <div className={props.mensaje.respuestaSoporte ? 'VerMensajesOut' : 'VerMensajesIn'}>
      <p>{props.mensaje.mensaje}</p>
      <h3>{castTimestampToShow(props.mensaje.fecha)}</h3>
    </div>
  )
}

function VerMensajes(props: VerMensajesProps) {

  const [loading, setLoading] = useState<boolean>(false)
  const [msj, setMsj] = useState<string>('')

  function handleEnviarMensaje() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.pqrs || !props.token) return
    const mensajeCorto = msj.trim().length <= 20
    const mensajeLargo = msj.trim().length >= 1000
    if (mensajeCorto) toast.error('El mensaje es muy corto')
    if (mensajeLargo) toast.error('El mensaje es muy largo')
    if (mensajeCorto || mensajeLargo) return
    setLoading(true)
    BackendAdminResponderPQRS({ pqrsId: props.pqrs.id, cliente: props.pqrs.email, mensaje: msj.trim() }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Mensaje enviado!')
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="VerMensajes">
      <h1>{`Cliente: ${props.pqrs?.cliente ?? ''}: ${props.pqrs?.email ?? ''}`}</h1>
      <div className='VerMensajesMsj'>
        {[...props.pqrs?.mensajes ?? []].reverse().map(msj => (
          <InfoMensaje key={msj.fecha} mensaje={msj} />
        ))}
      </div>
      <input
        type="text"
        placeholder="Respuesta..."
        value={msj}
        onChange={e => setMsj(e.target.value)}
      />
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : (<button onClick={handleEnviarMensaje}>
          <FontAwesomeIcon icon={faPaperPlane} color='#000000' size='2x' />
        </button>)}
    </div>
  );
};

export default VerMensajes;