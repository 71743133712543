import React, { useState } from 'react';
import './Modals.css';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminCompletarSolicitud, solicitudAdmin } from '../PeopleLoansSDKAdmin';
import { t } from '../traduccion/t';

interface CompletarSolicitudProps {
  solicitud: solicitudAdmin | undefined
  reloadInfo(): void
  token: string | undefined
}

function SubirPDF(props: { onFileChange: (pdfBase64: string) => void }) {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];

    if (selectedFile) {
      if (selectedFile.type !== 'application/pdf') {
        toast.warn('Por favor, seleccione un archivo PDF.');
        return;
      }

      setFile(selectedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          if (e.target) {
            const base64String = e.target.result as string;
            props.onFileChange(base64String.replace('data:application/pdf;base64,', ''));
          } else {
            toast.error(`Error subiendo PDF: e.target is null`)
          }
        } catch (error) {
          toast.error(`Error subiendo PDF: ${error}`)
          console.log(error)
        }
      };
      reader.onerror = (e) => {
        toast.error(`Error subiendo PDF: ${e.target}`)
        console.log(e)
      }
      reader.readAsDataURL(selectedFile); // base64
    }
  };

  return (
    <div className='SubirPDF'>
      <p>Comprobante de desembolso: {file?.name ?? ''}</p>
      <input
        type="file"
        accept=".pdf"
        onChange={handleFileChange} />
    </div>
  );
}

function InfoSolicitud(props: { titulo: string, info: string, colorInfo?: string }) {
  return (
    <div className='DefaultTwoHHorizontal'>
      <h1>{props.titulo}</h1>
      <h2 style={{ color: props.colorInfo ?? '#000000' }}>{props.info}</h2>
    </div>
  )
}

function CompletarSolicitud(props: CompletarSolicitudProps) {

  const [loading, setLoading] = useState<boolean>(false)
  const [pdfBase64, setPdfBase64] = useState<string | undefined>(undefined);

  function handleCompletarSolicitud() {
    if (!pdfBase64) {
      toast.warn('Suba un archivo PDF primero!')
      return
    }
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.solicitud || !props.token) return
    setLoading(true)
    BackendAdminCompletarSolicitud({ solicitudId: props.solicitud.id, pdf: pdfBase64 }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Solicitud completada!')
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="CompletarSolicitud">
      <InfoSolicitud titulo='ID de la solicitud:' info={props.solicitud?.id ?? ''} />
      <InfoSolicitud titulo='Cliente asociado:' info={`${props.solicitud?.cliente ?? ''}: ${props.solicitud?.email ?? ''}`} />
      <SubirPDF onFileChange={setPdfBase64} />
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={handleCompletarSolicitud}>Aprobar Solicitud</button>}
    </div>
  );
};

export default CompletarSolicitud;