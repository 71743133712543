import React from 'react';
import './App.css';
import logo from './Imgs/logo.png'

interface HeaderProps {
}

function Header(props:HeaderProps) {
  return (
    <div className="Header">
      <img src={logo} alt='Logo'></img>
      <h1>People Loans Admin Center</h1>
      <img src={logo} alt='Logo'></img>
    </div>
  );
}

export default Header;