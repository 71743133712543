export const castNumberToShow = (num: number): string => {
    if(num < 1000){
        return `${num.toFixed(0)}`
    }
    if(num < 1000000){
        return `${(num / 1000).toFixed(1)}k`
    }
    if(num < 1000000000){
        return `${(num / 1000000).toFixed(1)}M`
    }
    return num.toString()
}

export const castMinutesToShow = (min: number): string => {
    if(min < 60){
        return `${min} min`
    }else if(min < 1440){
        return `${Math.floor(min / 60)}h ${min % 60}min`
    }else{
        return `${Math.floor(min / 1440)}d ${Math.floor((min % 1440) / 60)}h`
    }
}

export const castToTwoDigitNumber = (num: number): string => num < 10 ? `0${num}` : `${num}`

export const castTimestampToShow = (timestamp: string): string => {
    if(timestamp === '0000000000000') return 'N/A'
    if(timestamp === '9999999999999') return '*Inmediato*'
    const date = new Date(parseInt(timestamp))
    return `${castToTwoDigitNumber(date.getDate())}/${castToTwoDigitNumber(date.getMonth() + 1)}/${date.getFullYear()} ${castToTwoDigitNumber(date.getHours())}:${castToTwoDigitNumber(date.getMinutes())}`
}

export const castMoneyToShow = (num: number, moneda: string): string => `$${num.toLocaleString('es-ES', {style:'decimal', 'minimumFractionDigits':0,'maximumFractionDigits':0})} ${moneda}`

export const castTasaToShow = (tasa: number): string => `${tasa}%`