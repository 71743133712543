import React, { useEffect, useState } from 'react';
import './Modals.css';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminAprobarSolicitud, solicitudAdmin } from '../PeopleLoansSDKAdmin';
import ModalConfirm from './ModalConfirm';
import { castMoneyToShow } from '../Helpers/textOutput';
import { t } from '../traduccion/t';

interface AprobarSolicitudProps {
  solicitud: solicitudAdmin | undefined
  reloadInfo(): void
  token: string | undefined
}

function AprobarSolicitud(props: AprobarSolicitudProps) {

  const [loading, setLoading] = useState<boolean>(false)
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)

  const [tasa, setTasa] = useState<number>(0)
  const [valor, setValor] = useState<number>(0)
  const [cuotas, setCuotas] = useState<number>(0)
  const [seguro, setSeguro] = useState<number>(0);

  useEffect(() => {
    setTasa(props.solicitud?.tasa ?? 0)
    setValor(props.solicitud?.cantidad ?? 0)
    setCuotas(props.solicitud?.cuotas ?? 0)
  }, [props.solicitud])

  function handleAprobarSolicitud() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.solicitud || !props.token) return
    const validTasa = tasa > 0
    const validValor = valor <= props.solicitud.cantidad
    const validCuotas = cuotas >= 1
    if (!validTasa) toast.error('La tasa debe ser positiva')
    if (!validValor) toast.error('El dinero aprobado debe ser menor o igual al solicitado')
    if (!validCuotas) toast.error('Las cuotas deben ser más de 0')
    if (!validTasa || !validValor || !validCuotas) return
    setLoading(true)
    BackendAdminAprobarSolicitud({ solicitudId: props.solicitud.id, tasa, valor, cuotas, seguro }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Solicitud aprobada!')
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="AprobarSolicitud">
      <h1>{`Solicitud a aprobar: ${props.solicitud?.id ?? ''}`}</h1>
      <h2>Valor aprobado ($COP)</h2>
      <input
        type="number"
        step={100000}
        min={0}
        value={valor}
        onChange={e => setValor(Number(e.target.value))} />
      <h2 style={{ color: '#808080', marginTop: 0 }}>{`Valor aprobado: ${castMoneyToShow(valor, 'COP')}`}</h2>
      <h2>Tasa aprobada (EA)</h2>
      <input
        type="number"
        step={0.1}
        min={0}
        value={tasa}
        onChange={e => setTasa(Number(e.target.value))} />
      <h2>Cuotas aprobadas (meses)</h2>
      <input
        type="number"
        step={1}
        min={1}
        value={cuotas}
        onChange={e => setCuotas(Math.round(Number(e.target.value)))} />
      <h2>Valor del seguro</h2>
      <input
        type="number"
        step={10000}
        min={0}
        value={seguro}
        onChange={e => setSeguro(Number(e.target.value))} />
      <h2 style={{ color: '#808080', marginTop: 0 }}>{`Valor seguro: ${castMoneyToShow(seguro, 'COP')}`}</h2>
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={() => !loading ? setConfirmVisible(true) : {}}>Aprobar Solicitud</button>}
      <ModalConfirm
        titulo='Asegurese de querer aprobar la solicitud'
        subtitulo='Esta acción no se puede deshacer'
        visibility={{ visible: confirmVisible, setVisible: setConfirmVisible }}
        accionConfirmar={handleAprobarSolicitud}
      />
    </div>
  );
};

export default AprobarSolicitud;