import React, { useEffect, useState } from 'react';
import './Modals.css';
import { tope } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import { castTipoCredito } from '../Helpers/casterBack';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminCambiarTope } from '../PeopleLoansSDKAdmin';
import { t } from '../traduccion/t';
import { castMoneyToShow } from '../Helpers/textOutput';

interface EditarTopesProps {
  tope: tope | undefined
  reloadTopes(): void
  token: string | undefined
}

function EditarTopes(props: EditarTopesProps) {

  const [valMin, setValMin] = useState<number>(props.tope?.minimo ?? 0)
  const [valMax, setValMax] = useState<number>(props.tope?.maximo ?? 0)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setValMin(props.tope?.minimo ?? 0)
    setValMax(props.tope?.maximo ?? 0)
  }, [props.tope])

  function handleCambiarTopes() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.tope || !props.token) return
    const validMin = valMin > 0
    const validMax = valMax > 0
    const validRelation = valMin <= valMax
    if (!validMin) toast.error('El valor debe ser mayor a 0')
    if (!validMax) toast.error('El valor debe ser mayor a 0')
    if (!validRelation) toast.error('El mínimo debe ser menor al máximo')
    if (!validMin || !validMin || !validRelation) return
    setLoading(true)
    BackendAdminCambiarTope({ credito: props.tope.id, minimo: valMin, maximo: valMax }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Topes actualizados!')
          props.reloadTopes()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="EditarTopes">
      <h1>Crédito a editar: {castTipoCredito(props.tope?.id ?? '')}</h1>
      <h2>{`Valor mínimo: ${castMoneyToShow(valMin, 'COP')}`}</h2>
      <input
        type="number"
        step={500000}
        min={0}
        value={valMin}
        onChange={e => setValMin(Number(e.target.value))} />
      <h2>{`Valor máximo: ${castMoneyToShow(valMax, 'COP')}`}</h2>
      <input
        type="number"
        step={500000}
        min={0}
        value={valMax}
        onChange={e => setValMax(Number(e.target.value))} />
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={handleCambiarTopes}>Guardar</button>}
    </div>
  );
};

export default EditarTopes;