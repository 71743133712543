import React from 'react';
import './App.css';

interface LoadingProps {
}

function Loading(props:LoadingProps) {
  return (
    <div className="Loading">
      <p>Cargando...</p>
    </div>
  );
}

export default Loading;