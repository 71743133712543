/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './AdminPanel.css';
import { usuarioAdmin, productoAdmin, solicitudAdmin, pqrsAdmin } from '../PeopleLoansSDKAdmin';
import { lista } from './AdminPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faCar, faCheck, faClock, faCoins, faDollar, faExclamationCircle, faEye, faFile, faFileAlt, faMessage, faMoneyBill, faPencil, faQuestionCircle, faRotate, faSearch, faTimes, faUser, faWarning } from '@fortawesome/free-solid-svg-icons';
import { castEstadoSolicitud, castPais, castRubroPago, castTipoCredito, getCuotaActual, getEstadoColor } from '../Helpers/casterBack';
import { castMinutesToShow, castMoneyToShow, castNumberToShow, castTasaToShow, castTimestampToShow } from '../Helpers/textOutput';
import VerCliente from '../Modals/VerCliente';
import Modal from '../Modals/Modal';
import RechazarSolicitud from '../Modals/RechazarSolicitud';
import VerDocumentos from '../Modals/VerDocumentos';
import VerCuotas from '../Modals/VerCuotas';
import { puedePasarAlEstado } from '../PeopleLoansSDKAdmin/node_modules/@peopleloans/peopleloanssdk'
import CompletarSolicitud from '../Modals/CompletarSolicitud';
import AprobarSolicitud from '../Modals/AprobarSolicitud';
import VerMensajes from '../Modals/VerMensajes';
import EditarCredito from '../Modals/EditarCredito';
import CrearCarro from '../Modals/CrearCarro';
import { toast } from 'react-toastify';
import { t } from '../traduccion/t';

interface ListProps {
  listaSeleccionada: lista | undefined
  clientes: usuarioAdmin[]
  solicitudes: solicitudAdmin[]
  creditos: productoAdmin[]
  pqrs: pqrsAdmin[]
  llenarLista(listaElegida: lista | undefined, token: string | undefined, forceReload?: boolean): void
  token: string | undefined
}

function abrirPDF(url?: string, token?: string) {
  if (!url || !token) return;
  toast.warn('Abriendo PDF, espera...')
  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
    .then(async r => {
      let bodyResponse: any = undefined;
      try {
        bodyResponse = await r.json()
      } catch { }
      return ({
        status: r.status,
        body: bodyResponse?.results ?? bodyResponse
      })
    })
    .then(r => {
      if (r.status === 404) {
        toast.error(t('es', r.body?.message ?? 'Error desconocido'))
      } else {
        const byteCharacters = atob(r.body);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        window.open(
          URL.createObjectURL(new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' })),
          '_blank');
      }
    })
    .catch(e => {
      toast.error(`Error: ${e}`)
      console.log(e)
    })
}

function ListItem(props: { titulo?: string, info?: string, icon?: IconDefinition, colorInfo?: string, link?: { text: string, url: string, token: string } }) {
  return (
    <div className='DefaultRows'>
      {props.icon && <FontAwesomeIcon icon={props.icon} color='#808080' size='1x' />}
      {props.titulo && <h1>{props.titulo}</h1>}
      {props.info && <h2 style={{ color: props.colorInfo ?? '#000000' }}>{props.info}</h2>}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {props.link && <a onClick={() => abrirPDF(props.link?.url, props.link?.token)}>{props.link.text}</a>}
    </div>
  )
}

interface ListClienteProps {
  cliente: usuarioAdmin
  setCliente: React.Dispatch<React.SetStateAction<usuarioAdmin | undefined>>
  setVerModalCliente: React.Dispatch<React.SetStateAction<boolean>>
}

function ListCliente(props: ListClienteProps) {
  return (
    <div className='ListItem'>
      {props.cliente.avatar ? (<img src={props.cliente.avatar} alt='Imagen usuario' />) : (<FontAwesomeIcon icon={faUser} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />)}
      <ListItem titulo={'Nombre'} info={props.cliente.infoBasica.nombre} />
      <ListItem titulo={'Email'} info={props.cliente.id} />
      <ListItem titulo={'Teléfono'} info={props.cliente.infoBasica.telefono} />
      <ListItem titulo={'Documento'} info={`${props.cliente.infoPersonal?.tipoId ?? ''} ${props.cliente.infoPersonal?.numeroId ?? ''}`} />
      <ListItem titulo={'Ubicación'} info={`${props.cliente.infoPersonal?.ciudad ?? ''}, ${props.cliente.infoPersonal?.estado ?? ''}, ${castPais(props.cliente.infoPersonal?.pais ?? '')}`} />
      <ListItem titulo={'Dirección'} info={props.cliente.infoPersonal?.direccion ?? ''} />
      <ListItem titulo={'Ingresos'} info={castMoneyToShow(props.cliente.infoFinanciera?.ingresos ?? 0, props.cliente.infoFinanciera?.moneda ?? 'COP')} />
      <ListItem titulo={'Gastos'} info={castMoneyToShow(props.cliente.infoFinanciera?.gastos ?? 0, props.cliente.infoFinanciera?.moneda ?? 'COP')} />
      <ListItem titulo={'Activos'} info={castMoneyToShow(props.cliente.infoFinanciera?.activos ?? 0, props.cliente.infoFinanciera?.moneda ?? 'COP')} />
      <ListItem icon={faFileAlt} info={castNumberToShow(props.cliente.numSolicitudes)} />
      <ListItem titulo={'Dinero Solicitado'} info={castMoneyToShow(props.cliente.dineroSolicitudes, 'COP')} />
      <ListItem icon={faMoneyBill} info={castNumberToShow(props.cliente.numCreditos)} />
      <ListItem titulo={'Dinero Recibido'} info={castMoneyToShow(props.cliente.dineroCreditos, 'COP')} />
      <ListItem titulo={'Dinero Pagado'} info={castMoneyToShow(props.cliente.dineroPagado, 'COP')} />
      <ListItem titulo={'Pagado Intereses'} info={castMoneyToShow(props.cliente.dineroPagadoIntereses, 'COP')} />
      <ListItem titulo={'Pagado Mora'} info={castMoneyToShow(props.cliente.dineroPagadoMora, 'COP')} />
      <ListItem icon={faClock} info={castMinutesToShow(props.cliente.tiempoActividadApp)} />
      <ListItem icon={faQuestionCircle} info={castNumberToShow(props.cliente.numPQRS)} />
      <button onClick={() => {
        props.setCliente(props.cliente)
        props.setVerModalCliente(true)
      }}>
        <FontAwesomeIcon icon={faEye} color='#000000' size='2x' />
      </button>
    </div>
  );
}

interface ListSolicitudProps {
  solicitud: solicitudAdmin
  setSolicitud: React.Dispatch<React.SetStateAction<solicitudAdmin | undefined>>
  setVerModalRechazar: React.Dispatch<React.SetStateAction<boolean>>
  setVerModalDocumentos: React.Dispatch<React.SetStateAction<boolean>>
  setVerModalAprobar: React.Dispatch<React.SetStateAction<boolean>>
  setVerModalCompletar: React.Dispatch<React.SetStateAction<boolean>>
  setVerModalCarro: React.Dispatch<React.SetStateAction<boolean>>
}

function ListSolicitud(props: ListSolicitudProps) {
  const requiereCarro = props.solicitud.tipo === 'Vehicular' && props.solicitud.carro === undefined
  const listaParaAprobar = puedePasarAlEstado(props.solicitud, 'Aprobada').puede && !requiereCarro
  const listaParaCompletar = puedePasarAlEstado(props.solicitud, 'Completa').puede && !requiereCarro
  return (
    <div className='ListItem'>
      {requiereCarro && <FontAwesomeIcon icon={faCar} color='#CCCC00' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {listaParaCompletar && <FontAwesomeIcon icon={faMoneyBill} color='#00CC00' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {listaParaAprobar && <FontAwesomeIcon icon={faWarning} color='#CCCC00' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      <ListItem titulo={'Estado'} info={castEstadoSolicitud(props.solicitud.estado, listaParaCompletar)} colorInfo={getEstadoColor(props.solicitud.estado, listaParaCompletar)} />
      <ListItem titulo={'Cliente'} info={`${props.solicitud.cliente}: ${props.solicitud.email}`} />
      <ListItem titulo={'Crédito'} info={`${castTipoCredito(props.solicitud.tipo)}: ${castTasaToShow(props.solicitud.tasa)}`} />
      <ListItem icon={faDollar} info={castMoneyToShow(props.solicitud.cantidad, props.solicitud.moneda)} />
      <ListItem titulo={'Cuotas'} info={props.solicitud.cuotas.toString()} />
      <ListItem titulo={'Depositar a'} info={`${props.solicitud.banco}: ${props.solicitud.cuenta}`} />
      <ListItem titulo={'Asesor'} info={props.solicitud.asesor ?? ''} />
      <ListItem titulo={'Analista'} info={props.solicitud.analista ?? ''} />
      <ListItem titulo={'Notas del usuario'} info={props.solicitud.notas} />
      <ListItem titulo={'Fecha'} info={castTimestampToShow(props.solicitud.fecha)} />
      <ListItem titulo={'ID'} info={props.solicitud.id} />
      <ListItem titulo={'Estado'} info={castEstadoSolicitud(props.solicitud.estado, listaParaCompletar)} colorInfo={getEstadoColor(props.solicitud.estado, listaParaCompletar)} />
      {requiereCarro && <FontAwesomeIcon icon={faCar} color='#CCCC00' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {listaParaCompletar && <FontAwesomeIcon icon={faMoneyBill} color='#00CC00' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {listaParaAprobar && <FontAwesomeIcon icon={faWarning} color='#CCCC00' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      {requiereCarro && (
        <button onClick={() => {
          props.setSolicitud(props.solicitud)
          props.setVerModalCarro(true)
        }}>
          <FontAwesomeIcon icon={faCar} color='#000000' size='2x' />
        </button>
      )}
      {listaParaCompletar && (
        <button onClick={() => {
          props.setSolicitud(props.solicitud)
          props.setVerModalCompletar(true)
        }}>
          <FontAwesomeIcon icon={faCheck} color='#00FF00' size='2x' />
        </button>
      )}
      {listaParaAprobar && (
        <button onClick={() => {
          props.setSolicitud(props.solicitud)
          props.setVerModalAprobar(true)
        }}>
          <FontAwesomeIcon icon={faWarning} color='#000000' size='2x' />
        </button>
      )}
      <button onClick={() => {
        props.setSolicitud(props.solicitud)
        props.setVerModalDocumentos(true)
      }}>
        <FontAwesomeIcon icon={faFile} color='#000000' size='2x' />
      </button>
      {!(['Aprobada', 'Aceptada', 'Revision', 'Completa', 'Cancelada', 'Rechazada'].includes(props.solicitud.estado)) && (
        <button onClick={() => {
          props.setSolicitud(props.solicitud)
          props.setVerModalRechazar(true)
        }}>
          <FontAwesomeIcon icon={faTimes} color='#FF0000' size='2x' />
        </button>
      )}
    </div>
  );
}

interface ListCreditoProps {
  credito: productoAdmin
  setCredito: React.Dispatch<React.SetStateAction<productoAdmin | undefined>>
  setVerModalCuotas: React.Dispatch<React.SetStateAction<boolean>>
  setVerModalEditarCredito: React.Dispatch<React.SetStateAction<boolean>>
  token: string
}

function ListCredito(props: ListCreditoProps) {
  return (
    <div className='ListItem'>
      {props.credito.enMora && <FontAwesomeIcon icon={faWarning} color='#FF0000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      <ListItem titulo={'Crédito'} info={`${castTipoCredito(props.credito.tipo)}: ${castTasaToShow(props.credito.tasa)}`} />
      <ListItem titulo={'Cliente'} info={`${props.credito.cliente}: ${props.credito.email}`} />
      <ListItem icon={faDollar} info={castMoneyToShow(props.credito.cantidad, props.credito.moneda)} />
      <ListItem titulo={'Capital vivo'} info={castMoneyToShow(props.credito.balance, props.credito.moneda)} />
      <ListItem titulo={'Cuotas'} info={`${getCuotaActual(props.credito.pagos)}/${props.credito.cuotas}`} />
      <ListItem titulo={'Siguiente pago'} info={castMoneyToShow(props.credito.pagoMinimo, props.credito.moneda)} colorInfo={props.credito.fechaSiguientePago === '9999999999999' ? '#FF0000' : '#000000'} />
      <ListItem titulo={'Fecha de pago'} info={castTimestampToShow(props.credito.fechaSiguientePago)} colorInfo={props.credito.fechaSiguientePago === '9999999999999' ? '#FF0000' : '#000000'} />
      <ListItem key={'Capital'} titulo={castRubroPago('Capital')} info={castMoneyToShow(props.credito.pagos.reduce((acc, p) => acc + p.Capital.cantidadPagada, 0), props.credito.moneda)} />
      <ListItem key={'Intereses'} titulo={castRubroPago('Intereses')} info={castMoneyToShow(props.credito.pagos.reduce((acc, p) => acc + p.Intereses.cantidadPagada, 0), props.credito.moneda)} />
      <ListItem key={'Seguros'} titulo={castRubroPago('Seguros')} info={castMoneyToShow(props.credito.pagos.reduce((acc, p) => acc + p.Seguros.cantidadPagada, 0), props.credito.moneda)} />
      <ListItem key={'Cobranza'} titulo={castRubroPago('Cobranza')} info={castMoneyToShow(props.credito.pagos.reduce((acc, p) => acc + p.Cobranza.cantidadPagada, 0), props.credito.moneda)} />
      <ListItem key={'Otros'} titulo={castRubroPago('Otros')} info={castMoneyToShow(props.credito.pagos.reduce((acc, p) => acc + p.Otros.cantidadPagada, 0), props.credito.moneda)} />
      <ListItem key={'Mora'} titulo={castRubroPago('Mora')} info={castMoneyToShow(props.credito.pagos.reduce((acc, p) => acc + p.Mora.cantidadPagada, 0), props.credito.moneda)} />
      <ListItem titulo={'ID'} info={props.credito.id} />
      <ListItem titulo={'Fecha'} info={castTimestampToShow(props.credito.fecha)} />
      <ListItem titulo={'Evidencia desembolso'} link={{ text: 'Documento PDF', url: props.credito.docDesembolso, token: props.token }} />
      {props.credito.enMora && <FontAwesomeIcon icon={faWarning} color='#FF0000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
      <button onClick={() => {
        props.setCredito(props.credito)
        props.setVerModalCuotas(true)
      }}>
        <FontAwesomeIcon icon={faCoins} color='#000000' size='2x' />
      </button>
      <button onClick={() => {
        props.setCredito(props.credito)
        props.setVerModalEditarCredito(true)
      }}>
        <FontAwesomeIcon icon={faPencil} color='#000000' size='2x' />
      </button>
    </div>
  );
}

interface ListPQRSProps {
  pqrs: pqrsAdmin
  setPQRS: React.Dispatch<React.SetStateAction<pqrsAdmin | undefined>>
  setVerModalPQRS: React.Dispatch<React.SetStateAction<boolean>>
}

function ListPQRS(props: ListPQRSProps) {
  try {
    const ultimoMensaje = props.pqrs.mensajes[0]
    return (
      <div className='ListItem'>
        {!ultimoMensaje.respuestaSoporte && <FontAwesomeIcon icon={faExclamationCircle} color='#FF0000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
        <ListItem titulo={'Cliente'} info={`${props.pqrs.cliente}: ${props.pqrs.email}`} />
        <ListItem titulo={'Email'} info={props.pqrs.email} />
        <ListItem titulo={'ID'} info={props.pqrs.id} />
        <ListItem titulo={'Último mensaje'} info={ultimoMensaje.mensaje} />
        <ListItem titulo={'Fecha último mensaje'} info={castTimestampToShow(ultimoMensaje.fecha)} />
        {!ultimoMensaje.respuestaSoporte && <FontAwesomeIcon icon={faExclamationCircle} color='#FF0000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />}
        <button onClick={() => {
          props.setPQRS(props.pqrs)
          props.setVerModalPQRS(true)
        }}>
          <FontAwesomeIcon icon={faMessage} color='#000000' size='2x' />
        </button>
      </div>
    )
  } catch (e) { return (<></>) }
}

function List(props: ListProps) {
  const [search, setSearch] = useState<string>('')
  useEffect(() => setSearch(''), [props.listaSeleccionada])

  const [verModalCliente, setVerModalCliente] = useState<boolean>(false)
  const [cliente, setCliente] = useState<usuarioAdmin | undefined>(undefined)

  const [verModalRechazar, setVerModalRechazar] = useState<boolean>(false)
  const [verModalDocumentos, setVerModalDocumentos] = useState<boolean>(false)
  const [verModalAprobar, setVerModalAprobar] = useState<boolean>(false)
  const [verModalCompletar, setVerModalCompletar] = useState<boolean>(false)
  const [verModalCarro, setVerModalCarro] = useState<boolean>(false)
  const [solicitud, setSolicitud] = useState<solicitudAdmin | undefined>(undefined)

  const [verModalCuotas, setVerModalCuotas] = useState<boolean>(false)
  const [verModalEditarCredito, setVerModalEditarCredito] = useState<boolean>(false)
  const [credito, setCredito] = useState<productoAdmin | undefined>(undefined)

  const [verModalPQRS, setVerModalPQRS] = useState<boolean>(false)
  const [pqrs, setPQRS] = useState<pqrsAdmin | undefined>(undefined)

  const [reloadControlDocs, setReloadControlDocs] = useState<boolean>(false)

  function reloadInfo() {
    setVerModalCliente(false)
    setVerModalRechazar(false)
    setVerModalDocumentos(false)
    setVerModalAprobar(false)
    setVerModalCompletar(false)
    setVerModalCarro(false)
    setVerModalCuotas(false)
    setVerModalEditarCredito(false)
    setVerModalPQRS(false)
    setCliente(undefined)
    setSolicitud(undefined)
    setCredito(undefined)
    setPQRS(undefined)
    setReloadControlDocs(false)
    props.llenarLista(props.listaSeleccionada, props.token, true)
  }

  useEffect(() => {
    // Recargar la info en caso de cerrar el modal de ver documentos
    if (!verModalDocumentos && reloadControlDocs && solicitud !== undefined) {
      setReloadControlDocs(false)
      reloadInfo()
    } else if (verModalDocumentos && solicitud) {
      setReloadControlDocs(true)
    }
  }, [verModalDocumentos])

  return (
    <>
      <div className='SearchBar'>
        <FontAwesomeIcon icon={faSearch} color='#000000' size='2x' />
        <input
          type="text"
          placeholder="Búsqueda..."
          value={search}
          onChange={e => setSearch(e.target.value.toLowerCase())}
        />
        <button onClick={() => setSearch('')}>
          <FontAwesomeIcon icon={faTimes} color='#000000' size='1x' />
        </button>
        <button onClick={(e) =>
          props.llenarLista(props.listaSeleccionada, props.token, true)
        } style={{ marginLeft: 100 }}>
          <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' />
        </button>
      </div>
      <div className="List">
        <div className='DefaultRows'>
          {props.listaSeleccionada === 'Clientes' ? (
            <>
              {props.clientes
                .filter(i => JSON.stringify(i).toLowerCase().includes(search.trim()))
                .map(cliente => {
                  return (<ListCliente
                    cliente={cliente} key={cliente.id}
                    setVerModalCliente={setVerModalCliente}
                    setCliente={setCliente} />)
                })}
            </>
          ) : props.listaSeleccionada === 'Solicitudes' ? (
            <>
              {props.solicitudes
                .filter(i => JSON.stringify(i).toLowerCase().includes(search.trim()))
                .map(solicitud => {
                  return (<ListSolicitud
                    solicitud={solicitud} key={solicitud.id}
                    setVerModalRechazar={setVerModalRechazar}
                    setVerModalDocumentos={setVerModalDocumentos}
                    setVerModalAprobar={setVerModalAprobar}
                    setVerModalCompletar={setVerModalCompletar}
                    setVerModalCarro={setVerModalCarro}
                    setSolicitud={setSolicitud}
                  />)
                })}
            </>
          ) : props.listaSeleccionada === 'Creditos' ? (
            <>
              {props.creditos
                .filter(i => JSON.stringify(i).toLowerCase().includes(search.trim()))
                .map(credito => {
                  return (<ListCredito
                    credito={credito} key={credito.id}
                    setVerModalCuotas={setVerModalCuotas}
                    setVerModalEditarCredito={setVerModalEditarCredito}
                    setCredito={setCredito}
                    token={props.token ?? ''} />)
                })}
            </>
          ) : props.listaSeleccionada === 'PQRS' ? (
            <>
              {props.pqrs
                .filter(i => JSON.stringify(i).toLowerCase().includes(search.trim()))
                .map(pqrs => {
                  return (<ListPQRS
                    pqrs={pqrs} key={`${pqrs.email}#${pqrs.id}`}
                    setVerModalPQRS={setVerModalPQRS}
                    setPQRS={setPQRS} />)
                })}
            </>
          ) : <></>}
        </div>
        <Modal title='Información completa del cliente' component={VerCliente({ token: props.token, cliente: cliente })} visibility={{ visible: verModalCliente, setVisible: setVerModalCliente }} />
        <Modal title='Rechazar solicitud' component={RechazarSolicitud({ token: props.token, solicitud: solicitud, reloadInfo: reloadInfo })} visibility={{ visible: verModalRechazar, setVisible: setVerModalRechazar }} />
        <Modal title='Revisar documentos de solicitud' component={VerDocumentos({ token: props.token, solicitud: solicitud })} visibility={{ visible: verModalDocumentos, setVisible: setVerModalDocumentos }} />
        <Modal title='Aprobar solicitud' component={AprobarSolicitud({ token: props.token, solicitud: solicitud, reloadInfo: reloadInfo })} visibility={{ visible: verModalAprobar, setVisible: setVerModalAprobar }} />
        <Modal title='Completar solicitud' component={CompletarSolicitud({ token: props.token, solicitud: solicitud, reloadInfo: reloadInfo })} visibility={{ visible: verModalCompletar, setVisible: setVerModalCompletar }} />
        <Modal title='Crear carro' component={CrearCarro({ token: props.token, solicitud: solicitud, reloadInfo: reloadInfo })} visibility={{ visible: verModalCarro, setVisible: setVerModalCarro }} />
        <Modal title='Revisar cuotas de crédito' component={VerCuotas({ token: props.token, credito: credito, reloadInfo: reloadInfo })} visibility={{ visible: verModalCuotas, setVisible: setVerModalCuotas }} />
        <Modal title='Editar crédito' component={EditarCredito({ token: props.token, credito: credito, reloadInfo: reloadInfo })} visibility={{ visible: verModalEditarCredito, setVisible: setVerModalEditarCredito }} />
        <Modal title='Centro de mensajes' component={VerMensajes({ token: props.token, pqrs: pqrs, reloadInfo: reloadInfo })} visibility={{ visible: verModalPQRS, setVisible: setVerModalPQRS }} />
      </div>
    </>
  );
}

export default List;