import React, { ReactNode } from 'react';
import './Modals.css';

interface ModalProps {
    component: ReactNode;
    title: string;
    visibility: {
        visible: boolean;
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
}

function Modal({ component, title, visibility }: ModalProps) {
    if (!visibility.visible) {
        return null;
    }

    return (
        <div onClick={() => visibility.setVisible(false)} className="modal-overlay">
            <div onClick={(e) => { e.stopPropagation() }} className="modal-content">
                <h1>{title}</h1>
                {component}
            </div>
        </div>
    );
};

export default Modal;