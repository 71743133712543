import React from 'react';
import './App.css';

interface FooterProps {
}

function Footer(props:FooterProps) {
  return (
    <div className="Footer">
      <p>People Loans Admin Center</p>
      <p>PeopleLoans 2024</p>
      <p>Todos los derechos reservados</p>
    </div>
  );
}

export default Footer;