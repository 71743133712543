import React from 'react';
import './Modals.css';

interface ModalConfirmProps {
    titulo: string;
    subtitulo: string;
    accionConfirmar(): void
    visibility: {
        visible: boolean;
        setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    };
}

function ModalConfirm(props: ModalConfirmProps) {
    if (!props.visibility.visible) {
        return null;
    }

    return (
        <div onClick={() => props.visibility.setVisible(false)} className="modal-overlay">
            <div onClick={(e) => { e.stopPropagation() }} className="ModalConfirm">
                <h1>{props.titulo}</h1>
                <h2>{props.subtitulo}</h2>
                <button className='ModalConfirmNo' onClick={() => props.visibility.setVisible(false)}>Cancelar</button>
                <button className='ModalConfirmYes' onClick={() => {
                    props.visibility.setVisible(false)
                    props.accionConfirmar()
                }}>Aceptar</button>
            </div>
        </div>
    );
};

export default ModalConfirm;