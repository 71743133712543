import React, { useEffect, useState } from 'react';
import './Modals.css';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { BackendAdminEditarCuotas, productoAdmin } from '../PeopleLoansSDKAdmin';
import ModalConfirm from './ModalConfirm';
import { t } from '../traduccion/t';

interface EditarCreditoProps {
  credito: productoAdmin | undefined
  reloadInfo(): void
  token: string | undefined
}

function EditarCredito(props: EditarCreditoProps) {

  const [loading, setLoading] = useState<boolean>(false)
  const [confirmVisibleTasa, setConfirmVisibleTasa] = useState<boolean>(false)
  const [confirmVisibleCuotas, setConfirmVisibleCuotas] = useState<boolean>(false)

  const [tasa, setTasa] = useState<number>(0)
  const [cuotas, setCuotas] = useState<number>(0)

  useEffect(() => {
    setTasa(props.credito?.tasa ?? 0)
    setCuotas(props.credito?.cuotas ?? 0)
  }, [props.credito])

  function handleEditarTasa() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.credito || !props.token) return
    const validTasa = tasa > 0 && tasa < props.credito.tasa
    if (!validTasa) toast.error('La tasa debe ser menor que la del crédito actual y positiva')
    if (!validTasa) return
    setLoading(true)
    BackendAdminEditarCuotas({ cdtId: props.credito.id, nuevaTasa: tasa }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Cuotas del crédito editadas!')
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  function handleEditarCuotas() {
    if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
    if (!props.token) toast.error('La sesión ha caducado, recargue la página!')
    if (loading || !props.credito || !props.token) return
    const validCuotas = cuotas > props.credito.cuotas
    if (!validCuotas) toast.error('Las cuotas deben ser más que las cuotas actuales')
    if (!validCuotas) return
    setLoading(true)
    BackendAdminEditarCuotas({ cdtId: props.credito.id, nuevaCantidadCuotas: cuotas }, props.token)
      .then(r => {
        if (r === undefined) {
          toast.success('Cuotas del crédito editadas!')
          props.reloadInfo()
        } else {
          toast.error(t('es', r.body?.message ?? 'Error desconocido'))
          console.log(r)
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`)
        console.log(e)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="EditarCredito">
      <h1>{`Crédito a editar: ${props.credito?.id ?? ''}`}</h1>
      <h2>Tasa (EA)</h2>
      <input
        type="number"
        step={0.1}
        min={0}
        value={tasa}
        onChange={e => setTasa(Number(e.target.value))} />
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={() => !loading ? setConfirmVisibleTasa(true) : {}}>Cambiar Tasa</button>}
      <ModalConfirm
        titulo='Asegurese de querer cambiar la tasa'
        subtitulo='Esta acción no se puede deshacer'
        visibility={{ visible: confirmVisibleTasa, setVisible: setConfirmVisibleTasa }}
        accionConfirmar={handleEditarTasa}
      />
      <h2 style={{ marginTop: 100 }}>Cuotas (meses)</h2>
      <input
        type="number"
        step={1}
        min={1}
        value={cuotas}
        onChange={e => setCuotas(Math.round(Number(e.target.value)))} />
      {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
        : <button onClick={() => !loading ? setConfirmVisibleCuotas(true) : {}}>Cambiar Cuotas</button>}
      <ModalConfirm
        titulo='Asegurese de querer cambiar el número de cuotas'
        subtitulo='Esta acción no se puede deshacer'
        visibility={{ visible: confirmVisibleCuotas, setVisible: setConfirmVisibleCuotas }}
        accionConfirmar={handleEditarCuotas}
      />
    </div>
  );
};

export default EditarCredito;