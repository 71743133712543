export const castTipoCredito = (tipoCredito: string): string => {
    switch(tipoCredito){
        case 'Junior':
            return 'Junior'
        case 'Vehicular':
            return 'Vehicular'
        case 'Libranza':
            return 'Libranza'
        case 'Libre':
            return 'Libre Inversión'
        default:
            return tipoCredito
    }
}

export const castPais = (pais: string): string => {
    switch(pais){
        case 'COL':
            return 'Colombia'
        default:
            return pais
    }
}

export const getEstadoColor = (estado: string, listaParaCompletar = false): string =>{
    if(listaParaCompletar) return '#FF9900' // Naranja 
    switch(estado){
        case 'Pendiente':
        case 'Revision':
            return '#FF0000' // Rojo
        case 'Incompleta':
        case 'Aprobada':
        case 'Aceptada':
            return '#FF9900' // Naranja
        case 'Completa':
            return '#00CC00' // Verde
        case 'Cancelada':
        case 'Rechazada':
            return '#808080' // Gris
        default:
            return '#000000' // Negro
    }
}

export const castEstadoSolicitud = (estado: string, listaParaCompletar = false): string =>{
    if(listaParaCompletar) return 'Lista para completar. Realizar desembolso y subir comprobante!'
    switch(estado){
        case 'Incompleta':
            return 'Incompleta. Esperando documentos...'
        case 'Pendiente':
            return 'Pendiente. Revisar documentos! Esperando aprobación.'
        case 'Aprobada':
            return 'Aprobada. Esperando aceptación del cliente...'
        case 'Aceptada':
            return 'Aceptada. Esperando firmas...'
        case 'Revision':
            return 'En revisión. Revisar firmas!'
        case 'Completa':
            return 'Completada!'
        case 'Rechazada':
            return 'Rechazada por Admin'
        case 'Cancelada':
            return 'Cancelada por usuario'
        default:
            return ''
    }
}

export const castRubroPago = (rubro: string): string => {
    switch(rubro){
        case 'Capital':
            return 'Pago a capital'
        case 'Intereses':
            return 'Pago en intereses'
        case 'Mora':
            return 'Pagos por mora'
        case 'Seguros':
            return 'Pago de seguros'
        case 'Cobranza':
            return 'Pago de cobranza'
        case 'Otros':
            return 'Otros pagos'
        default:
            return rubro
    }
}

export const getCuotaActual = (pagos: {fechaLimitePago: string}[]): number =>{
    const fechaActual = new Date().valueOf().toString()
    const indicePagoActual = pagos.findIndex(p => fechaActual <= p.fechaLimitePago)
    return indicePagoActual + 1
}

export const castNombreDocumento = (nombre: string): string => {
    switch(nombre){
        case 'DocumentoIdentificacion':
            return 'Documento de identificación'
        case 'ComprobanteIngresos':
            return 'Comprobante de ingresos'
        case 'ExtractosBancarios':
            return 'Extractos bancarios'
        case 'ComprobanteResidencia':
            return 'Comprobante de residencia'
        case 'CertificacionBancaria':
            return 'Certificación bancaria'
        case 'AutorizacionDescuentoNomina':
            return 'Autorización de descuento de nómina';
        case 'DocumentosVehiculo':
            return 'Documentos del vehículo'
        case 'ContratoMandato':
            return 'Contrato de mandato'
        case 'FormatoTramiteInscripcionPrenda':
            return 'Formato para trámite de inscripción de prenda'
        case 'SolicitudVinculacion':
            return 'Solicitud de vinculación'
        case 'CamaraComercio':
            return 'Camara de comercio'
        case 'RUT':
            return 'RUT'
        case 'CedulaRepresentanteLegal':
            return 'Cédula del representante legal'
        case 'ComposicionAccionaria':
            return 'Composición accionaria'
        case 'EstadosFinancieros':
            return 'Estados financieros'
        case 'DeclaracionRenta':
            return 'Declaración de renta'
        case 'DeclaracionIVA':
            return 'Declaraciones de IVA'
        case 'RelacionActivosFijos':
            return 'Relación de activos fijos'
        case 'PlanPagos':
            return 'Plan de pagos'
        case 'Contrato':
            return 'Contrato'
        case 'TerminosYCondiciones':
            return 'Términos y condiciones'
        case 'Pagare':
            return 'Pagaré'
        case 'CartaInstrucciones':
            return 'Carta de instrucciones'
        case 'EvidenciaDesembolso':
            return 'Evidencia de desembolso'
        case 'ComprobantePension':
            return 'Comprobantes de pensión'
        case 'CertificadoLaboral':
            return 'Certificado laboral'
        default:
            return nombre
    }
}