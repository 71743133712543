import React, { useState } from 'react';
import './App.css';
import { BackendAdminLogin } from './PeopleLoansSDKAdmin';
import { isValidEmail, isValidPassword } from './Helpers/textInput';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';

interface LoginProps {
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    email: { get: string, set: React.Dispatch<React.SetStateAction<string>> }
}

function Login(props: LoginProps) {
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    function handleLogin() {
        if (loading) toast.warn('Espere... Ya hay una solicitud en proceso!')
        if (loading) return
        const validEmail = isValidEmail(props.email.get)
        const validPassword = isValidPassword(password)
        if (!validEmail) toast.error('Email inválido')
        if (!validPassword) toast.error('El password debe tener al menos 8 caracteres')
        if (!validEmail || !validPassword) return
        props.setLoading(true)
        setLoading(true)
        BackendAdminLogin({ email: props.email.get, password })
            .then(r => {
                if (typeof r === 'string') {
                    toast.success('Ingreso exitoso!')
                    props.setToken(r)
                } else {
                    toast.error('Error iniciando sesión')
                    console.log(r)
                }
            })
            .catch(e => {
                toast.error(`Error: ${e}`)
                console.log(e)
            })
            .finally(() => { props.setLoading(false); setLoading(false) })
    }

    return (
        <div className="Login">
            <input
                type="text"
                placeholder="Email"
                value={props.email.get}
                onChange={e => props.email.set(e.target.value)}
            />
            <input
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            {loading ? <FontAwesomeIcon icon={faRotate} color='#000000' size='2x' style={{ alignSelf: 'center', padding: 10 }} />
                : <button onClick={handleLogin}>Iniciar sesión</button>}
        </div>
    );
}

export default Login;